import React, { useState, useEffect } from "react";
import "../assets/style.css";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import { GoDotFill } from "react-icons/go";
import Image1 from "../assets/images/intro_1-1.png";
import Image2 from "../assets/images/faq_1-1.png";
import Twitter from "../assets/images/affiliate/twitter-x.png";
import Telegram from "../assets/images/affiliate/Telegram.png";
import Whatsapp from "../assets/images/affiliate/whatsapp.png";
import Facebook from "../assets/images/affiliate/Fb.png";
import { LuPenSquare } from "react-icons/lu";
import { HiSpeakerphone } from "react-icons/hi";
import { BiMoney } from "react-icons/bi";
import { PiArrowUpRightBold } from "react-icons/pi";
import { TbMoneybag } from "react-icons/tb";
import { IoBookOutline } from "react-icons/io5";
import { TbUsersGroup } from "react-icons/tb";
import { TbArrowsShuffle } from "react-icons/tb";
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select'
import { useFormik } from "formik";
import { makeRequest } from "../core/services/v1/request";
import Config from '../core/config/index';
import * as Yup from "yup";
import 'yup-phone';
import { toast } from '../core/lib/toastAlert';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import phone from 'phone';
import { useNavigate } from "react-router-dom";
import { useContextData } from '../core/context/index';

export default function Affiliate(props) {

  const [selectOption, setSelectOption] = useState("Whatsapp");
  const navigate = useNavigate();
  const { ipAddress } = useContextData();
  const [vpnCountry, setVpnCountry] = useState('');

  const options = [
    // { value: 'select contact', label: 'Select Contact'},
    { value: 'Whatsapp', label: <div className="d-flex align-items-center gap-2"><img src={Whatsapp} alt="Whatsapp" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />Whatsapp</div> },
    { value: 'Telegram', label: <div className="d-flex align-items-center gap-2"><img src={Telegram} alt="Telegram" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />Telegram</div> },
    { value: 'Facebook', label: <div className="d-flex align-items-center gap-2"><img src={Facebook} alt="Facebook" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />Facebook</div> },
    { value: 'Twitter', label: <div className="d-flex align-items-center gap-2"><img src={Twitter} alt="Twitter" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />Twitter</div> }
  ]

  const customStyles = {
    menu: base => ({
      ...base,
      background: "var(--btnClr)",
      margin: "0px",
      padding: "0px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    control: (base, state) => ({
      ...base,
      background: "transparent",
      color: "#000",
      border: "0px solid transparent",
      boxShadow: state.isFocused ? "none" : base.boxShadow,
      fontSize: "13px",
      margin: "0px",
      padding: "0px",
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: "var(--background-color-3)", // Change background color to red
        color: "var(--color-1)",
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "var(--color-1)",
          color: "var(--background-color-1)",
        }
      };
    },
  };

  const defaultOption = options[0];

  const selectContact = (event) => {
    const newOption = event.value;
    // Clear the previous selected contact detail
    setFieldValue(`contactDetails.${selectOption.toLowerCase()}`, "");
    // Set the new selected option
    setSelectOption(newOption);
  };

  const { handleChange, handleBlur, handleSubmit, handleReset, errors, touched, values, setFieldValue } = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactDetails: {
        whatsapp: "",
        twitter: "",
        facebook: "",
        telegram: "",
      },
      youtube: "",
      youtubefolCount: "",
      insta: "",
      instaFolCount: "",
      facebook: "",
      facebookFolCount: "",
      telegram: "",
      telegramFolCount: ""
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Name is required'),
      email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
      contactDetails: Yup.object().shape({
        whatsapp: selectOption === 'Whatsapp' ? Yup.string().phone(vpnCountry, true, ' Number is invalid').required('WhatsApp number is required') : Yup.string(),
        twitter: selectOption === 'Twitter' ? Yup.string().matches(/^(https?:\/\/)?(www\.)?(x\.com\/[a-zA-Z0-9_]{1,15}|@[a-zA-Z0-9_]{1,15})$/, 'Twitter Username or URL must valid').required('Twitter ID is required') : Yup.string(),
        facebook: selectOption === 'Facebook' ? Yup.string().matches(/^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/[a-zA-Z0-9(\.\?)?]/, 'Facebook URL must valid').required('Facebook ID is required') : Yup.string(),
        telegram: selectOption === 'Telegram' ? Yup.string().matches(/^(https?:\/\/)?(www\.)?(t\.me\/|telegram\.me\/)?(@)?[a-zA-Z0-9_]{5,32}$/, 'Telegram Username or URL must valid').required('Telegram username is required') : Yup.string(),
      }),
      youtube: Yup.string().matches(/^(https?:\/\/)?(www\.)?(youtube\.com\/(channel\/|c\/|user\/|@)[a-zA-Z0-9_\-]+)?$/, 'Youtube field must be a valid channel link eg: www.youtube.com/@channelName').required("Youtube field is required!"),
      youtubefolCount: Yup.number().required("Subscribers count is required!"),
      insta: Yup.string().matches(/^@[a-zA-Z0-9_.]{1,30}$/, 'Insta ID must be a valid Instagram username in the format @username').required("Insta ID field is required!"),
      instaFolCount: Yup.number().required("Insta Followers count field is required!"),
      facebook: Yup.string().matches(/^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/[a-zA-Z0-9(\.\?)?]/, 'Facebook URL must valid').required("Facebook ID field is required!"),
      facebookFolCount: Yup.number().required("Facebook Followers count field is required!"),
      telegram: Yup.string().matches(/^(https?:\/\/)?(www\.)?(t\.me\/|telegram\.me\/)?(@)?[a-zA-Z0-9_]{5,32}$/, 'Invalid Telegram username or URL').required("Telegram channel link field is required!"),
      telegramFolCount: Yup.number().required("Telegram Subscribers count field is required!")
    }),
    onSubmit: async (values) => {
      const payload = {
        name: values.name,
        email: values.email,
        contactDetails: values.contactDetails.whatsapp ? values.contactDetails.whatsapp : values.contactDetails.telegram ? values.contactDetails.telegram : values.contactDetails.facebook ? values.contactDetails.facebook : values.contactDetails.twitter ? values.contactDetails.twitter : '',
        youtube: values.youtube,
        youtubefolCount: values.youtubefolCount,
        insta: values.insta,
        instaFolCount: values.instaFolCount,
        facebook: values.facebook,
        facebookFolCount: values.facebookFolCount,
        telegram: values.telegram,
        telegramFolCount: values.telegramFolCount
      }
      let params = {
        method: "POST",
        url: `${Config.V1_API_URL}user/addInfluencer`,
        data: payload
      }

      const response = await makeRequest(params);
      if (response.status == true) {
        toast({ type: "success", message: "Details submitted Successfully" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);

      } else {
        toast({ type: "error", message: "Please try again later" });
      }
    }
  })

  useEffect(() => {
    const fetchVpnCountry = async () => {
      try {
        setVpnCountry(ipAddress.country_name);
      } catch (error) {
        console.error('Error fetching VPN country', error);
      }
    };

    fetchVpnCountry();
  }, [ipAddress]);

  const handleChanges = (event, type) => {
    let newValue;

    if (event && event.target && event.target.value !== undefined) {
      // Regular input element change
      newValue = event.target.value;
    } else {
      // Assuming direct value change from PhoneInput or similar component
      newValue = event;
    }
    console.log("newValue---", newValue);
    // Update the formik state immutably
    setFieldValue(`contactDetails.${type}`, newValue);
  }

  const handleClose = () => {
    handleReset();
  };



  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />

      <div className="deposit-page-top-banner">
        <div className="affiliate-hero-section">
          <div className="container">
            <div className="row align-items-center text-center justify-content-center text-white">
              <div className="col-lg-10">
                <h1 className="affiliate-text-1 mb-4"><span className="affiliate-text-1-1 position-relative">The Getx</span> Affiliate <span className="affiliate-text-1-2 position-relative">Programme</span></h1>
                <p className="mb-1">Monetize your traffic and earn crypto commissions when you share Getx with your audiences. Content creators, influencers and platforms can earn commissions and also special rewards after becoming an influencer Tier on getx.</p>
                <p className="mb-5">The Getx Affiliate Program lets you share your unique referral link with audiences to earn up to 50% commission on every qualified trade.</p>
                <div>
                  <a className="affiliate-banner-button-2" data-bs-toggle="modal" data-bs-target="#affiliateinfluencers"> Join as an Affiliate <PiArrowUpRightBold className="affiliate-banner-button-2-icon-1" /></a>
                </div>
              </div>
            </div>
          </div>

          <div className="affiliate-second-section">
            <div className="container col-lg-10 mx-auto">
              <h3 className="text-center mb-5">How the Getx Affiliate Programme Works?</h3>
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <h3><LuPenSquare /></h3>
                  <h5>Registration</h5>
                  <p className="mb-3">Signing up for the Getx Affiliate Program is a simple process. Users can register through the Getx website, where they gain access to a dashboard with a unique referral link.</p>
                </div>
                <div className="col-lg-4">
                  <h3><HiSpeakerphone /></h3>
                  <h5>Promotion</h5>
                  <p className="mb-3">Once registered, affiliates can start promoting Getx using their unique referral links or codes. These links are crucial, as they track the activity of referred users and attribute them to the respective affiliates.</p>
                </div>
                <div className="col-lg-4">
                  <h3><BiMoney /></h3>
                  <h5>Earning Commissions</h5>
                  <p className="mb-3">Affiliates earn commissions based on the trading fees generated by their referrals. The commission rates are competitive, offering a percentage of the trading fees as a reward. Additionally, affiliates can enjoy tiered commission structures and bonuses for high-performance referrals.</p>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="affiliate-banner-button-3" data-bs-toggle="modal" data-bs-target="#affiliateinfluencers" >Become an Affiliate - Influencer Tier <PiArrowUpRightBold className="affiliate-banner-button-3-icon-1" /></button>
              </div>
            </div>
          </div>

          <div className="affiliate-third-section py-5">
            <div className="container">
              <div className="row justify-content-center align-items-end">
                <div className="col-lg-6">
                  <h3 className="mb-5">Benefits of Joining the GETX Affiliate Program</h3>
                  <div>
                    <h5 className="d-flex flex-row align-items-center gap-2 mb-4"><TbMoneybag style={{ color: "var(--btnClr)", fontSize: "24px" }} />Passive Income</h5>
                    <p className="mb-0">Affiliates can earn passive income by leveraging their network and promoting GETX to interested individuals. With a growing user base in the cryptocurrency space, the earning potential is substantial.</p>
                  </div>
                  <div className="mt-5">
                    <h5 className="d-flex flex-row align-items-center gap-2 mb-4"><IoBookOutline style={{ color: "var(--btnClr)", fontSize: "24px" }} />Educational Resources</h5>
                    <p className="mb-0">In addition to earning commissions, affiliates gain access to educational resources, market insights, and updates from GETX. This knowledge enrichment adds value to their experience and enhances their understanding of cryptocurrencies and blockchain technology.</p>
                  </div>
                  <div className="mt-5">
                    <h5 className="d-flex flex-row align-items-center gap-2 mb-4"><TbUsersGroup style={{ color: "var(--btnClr)", fontSize: "24px" }} />Community Engagement</h5>
                    <p className="mb-0">The program fosters a sense of community among affiliates, providing opportunities for networking, collaboration, and sharing best practices. It's a supportive ecosystem where members can learn from each other and thrive together.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="intro-thumb1 text-center position-relative mt-4 d-lg-block d-none">
                    <img src={Image1} alt="Benefits" />
                  </div>
                  <div className="mt-5">
                    <h5 className="d-flex flex-row align-items-center gap-2 mb-4"><TbArrowsShuffle style={{ color: "var(--btnClr)", fontSize: "24px" }} />Flexibility and Scalability</h5>
                    <p className="mb-0">Whether you're a seasoned marketer, a crypto enthusiast, or someone exploring new avenues, the GETX Affiliate Program offers flexibility and scalability. Affiliates can tailor their promotional efforts to suit their expertise and expand their reach over time.</p>
                  </div>
                </div>
              </div>
              <p className="mt-4 text-center w-100">Seems very beneficial Right? Sign Up Today to Start Earning on Getx</p>
              <div className="text-center">
                <button type="submit" className="affiliate-banner-button-4" data-bs-toggle="modal" data-bs-target="#affiliateinfluencers">Become an Affiliate - Influencer Tier <PiArrowUpRightBold className="affiliate-banner-button-4-icon-1" /></button>
              </div>
            </div>
          </div>

          <div className="affiliate-fourth-section pt-5">
            <div className="container">
              <div className="row justify-content-lg-around align-items-center g-4">
                <div className="col-lg-6">
                  <h3 className="mb-lg-5">Frequently Asked Questions</h3>
                  <img src={Image2} alt="FAQ" />
                </div>
                <div className="col-lg-6">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="mb-3">
                      <Accordion.Header>How can I earn a bonus on Getx every month?</Accordion.Header>
                      <Accordion.Body>
                        Influencers get rewards from their referral fees each month. More fees mean a bigger bonus for you.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="mb-3">
                      <Accordion.Header>What are the conditions to become an Influencer on Getx?</Accordion.Header>
                      <Accordion.Body>
                        Any normal user on Getx can become an Influencer.  There is no particular eligibility other than referring Getx to others.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="mb-3">
                      <Accordion.Header>How can I become an Affiliate on Getx?</Accordion.Header>
                      <Accordion.Body>
                        You can simply register and enter into your dashboard you can see the unique referral link to earn commission.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="mb-3">
                      <Accordion.Header>How can I become an Affiliate - Influencer Tier on Getx?</Accordion.Header>
                      <Accordion.Body>
                        You can simply fill out the form, and start sharing your referral links to others to become an Affiliate Influencer Tier.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="mb-3">
                      <Accordion.Header>Where to apply to get Influencer Tier of Getx?</Accordion.Header>
                      <Accordion.Body>
                        You can use this “CTA &gt;&gt;&gt; Become an Affiliate - Influencer Tier (Leads to influencer form)” on getx
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>


      <div className="modal fade" id="affiliateinfluencers" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content application-form-ido-section p-0">
            <div className="modal-header" style={{ borderColor: "var(--border-color-3)" }}>
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Influencer Tier</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <form className="my-3" onSubmit={(e) => { e.preventDefault(); handleSubmit(); return false; }}>
              <div className="modal-body ">
                <div className="mb-2">
                  <p className="ido-active-text-2 w-100 mb-1">Name <span className="text-danger">*</span></p>
                  <input
                    type="text"
                    className="form-control py-2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="name"
                    value={values.name || ""}
                  />
                  {
                    touched.name && errors.name && (
                      <small className="invalid-email error password-text-33">
                        {errors.name}
                      </small>
                    )
                  }
                </div>
                <div className="mb-2">
                  <p className="ido-active-text-2 w-100 mb-1">Email Id <span className="text-danger">*</span></p>
                  <input
                    type="email"
                    className="form-control py-2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="email"
                    value={values.email || ""}
                  />
                  {
                    touched.email && errors.email && (
                      <small className="invalid-email error password-text-33">
                        {errors.email}
                      </small>
                    )
                  }
                </div>
                <div className="mb-2">
                  <p className="ido-active-text-2 w-100 mb-1">Contact Details <span className="text-danger">*</span></p>
                  <div class="input-group py-1 influ-css-grodp-1 flex-nowrap" style={{ border: "1px solid var(--border-color-2)", borderRadius: "4px" }}>
                    {
                      selectOption == "Whatsapp" ?
                        <PhoneInput
                          international
                          className="px-0 text-white"
                          name="whatsapp"
                          defaultCountry={vpnCountry}
                          placeholder="Enter phone number"
                          onChange={(event) => handleChanges(event, "whatsapp")}
                          onBlur={handleBlur}
                          value={values.contactDetails.whatsapp || ""}
                        />
                        :
                        selectOption == "Telegram" ?
                          <input
                            type="text"
                            name="telegram"
                            class="form-control border-0"
                            onChange={(event) => {
                              handleChanges(event, "telegram");
                            }}
                            placeholder="Enter Telegram Username or URL"
                            onBlur={handleBlur}
                            value={values.contactDetails.telegram || ""}
                          />
                          :
                          selectOption == "Facebook" ?
                            <input
                              type="text"
                              name="facebook"
                              class="form-control border-0"
                              onChange={(event) => {
                                handleChanges(event, "facebook");
                              }}
                              placeholder="Enter Facebook URL"
                              onBlur={handleBlur}
                              value={values.contactDetails.facebook || ""}
                            />
                            :
                            selectOption == "Twitter" ?
                              <input
                                type="text"
                                name="twitter"
                                class="form-control border-0"
                                onChange={(event) => {
                                  handleChanges(event, "twitter")
                                }}
                                placeholder="Enter Twitter Username or URL"
                                onBlur={handleBlur}
                                value={values.contactDetails.twitter || ""}
                              />
                              :
                              <input
                                type="text"
                                name="twitter"
                                class="form-control border-0"
                              />
                    }
                    <Select
                      options={options}
                      styles={customStyles}
                      onChange={selectContact}
                      defaultValue={defaultOption}
                      placeholder="Select Contact"
                      isSearchable={false}
                      className="set-deft-select-wid"
                    />
                  </div>
                  {
                    touched.contactDetails && (
                      <>
                        {selectOption === "Whatsapp" && touched.contactDetails.whatsapp && errors.contactDetails?.whatsapp && (
                          <small className="invalid-email error password-text-33">
                            {errors.contactDetails.whatsapp}
                          </small>
                        )}
                        {selectOption === "Telegram" && touched.contactDetails.telegram && errors.contactDetails?.telegram && (
                          <small className="invalid-email error password-text-33">
                            {errors.contactDetails.telegram}
                          </small>
                        )}
                        {selectOption === "Facebook" && touched.contactDetails.facebook && errors.contactDetails?.facebook && (
                          <small className="invalid-email error password-text-33">
                            {errors.contactDetails.facebook}
                          </small>
                        )}
                        {selectOption === "Twitter" && touched.contactDetails.twitter && errors.contactDetails?.twitter && (
                          <small className="invalid-email error password-text-33">
                            {errors.contactDetails.twitter}
                          </small>
                        )}
                      </>
                    )
                  }
                </div>
                <div className="row mb-2">
                  <div className="col-lg-7">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ lineHeight: "26px" }}>Youtube channel Link <span className="text-danger">*</span></p>
                      <input
                        type="text"
                        className="form-control"
                        name="youtube"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.youtube || ""}
                      />
                    </div>
                    {
                      touched.youtube && errors.youtube && (
                        <small className="invalid-email error password-text-33">
                          {errors.youtube}
                        </small>
                      )
                    }
                  </div>
                  <div className="col-lg-5 mt-2 mt-lg-0">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px", lineHeight: "26px" }}>Subscribers count</p>
                      <input
                        type="Number"
                        className="form-control"
                        name="youtubefolCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.youtubefolCount || ""}
                      />
                      {
                        touched.youtubefolCount && errors.youtubefolCount && (
                          <small className="invalid-email error password-text-33">
                            {errors.youtubefolCount}
                          </small>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-7">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ lineHeight: "26px", lineHeight: "26px" }}>Insta ID<span className="text-danger">*</span></p>
                      <input
                        type="text"
                        className="form-control"
                        name="insta"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.insta || ""}
                      />
                      {
                        touched.insta && errors.insta && (
                          <small className="invalid-email error password-text-33">
                            {errors.insta}
                          </small>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-lg-5 mt-2 mt-lg-0">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px" }}>Insta Followers count</p>
                      <input
                        type="Number"
                        className="form-control"
                        name="instaFolCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instaFolCount || ""}
                      />
                      {
                        touched.instaFolCount && errors.instaFolCount && (
                          <small className="invalid-email error password-text-33">
                            {errors.instaFolCount}
                          </small>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-7">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ lineHeight: "26px" }}>Facebook ID<span className="text-danger">*</span></p>
                      <input
                        type="text"
                        className="form-control"
                        name="facebook"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.facebook || ""}
                      />
                      {
                        touched.facebook && errors.facebook && (
                          <small className="invalid-email error password-text-33">
                            {errors.facebook}
                          </small>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-lg-5 mt-2 mt-lg-0">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px", lineHeight: "26px" }}>Facebook Followers count</p>
                      <input
                        type="Number"
                        className="form-control"
                        name="facebookFolCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.facebookFolCount || ""}
                      />
                      {
                        touched.facebookFolCount && errors.facebookFolCount && (
                          <small className="invalid-email error password-text-33">
                            {errors.facebookFolCount}
                          </small>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-7">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ lineHeight: "26px" }}>Telegram Group or channel Name<span className="text-danger">*</span></p>
                      <input
                        type="text"
                        className="form-control"
                        name="telegram"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telegram || ""}
                      />
                      {
                        touched.telegram && errors.telegram && (
                          <small className="invalid-email error password-text-33">
                            {errors.telegram}
                          </small>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-lg-5 mt-2 mt-lg-0">
                    <div className="">
                      <p className="ido-active-text-2 w-100 mb-1" style={{ fontSize: "12px", lineHeight: "26px" }}>Member / Subscribers count</p>
                      <input
                        type="Number"
                        className="form-control"
                        name="telegramFolCount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.telegramFolCount || ""}
                      />
                      {
                        touched.telegramFolCount && errors.telegramFolCount && (
                          <small className="invalid-email error password-text-33">
                            {errors.telegramFolCount}
                          </small>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="text-center d-grid mt-4">
                  <button type="submit" className="banner-top-button-copy">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


      <Footer />
    </div>
  );
}
