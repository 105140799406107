import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch, } from "react-icons/ai";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { showPairName } from "../../../core/helper/date-format";
import Config from '../../../core/config/';
import { FaSearch } from "react-icons/fa";
import Pairtable from './Pair-table';



export default function PairListAndSearch(props) {
  const { marketPriceColor } = props;
  const [isActive, setActive] = useState(false);
  const dropdownRef = useRef(null);

  const ToggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    $(".spot-page-pair-select-dropdown .dropdown-menu").click(function (e) {
      e.stopPropagation();
    });
    $(".right-arrow").hide();
    $(".left-arrow").click(function () {
      const left = document.querySelector(".overflow-x-scroll-arrow-section");
      left.scrollBy(200, 0);
      $(".right-arrow").show();
    });   
    $(".right-arrow").click(function () {
      const right = document.querySelector(".overflow-x-scroll-arrow-section");
      right.scrollBy(-200, 0);
    });
    setTimeout(() => $("#pills-hot-tabUSDT").click(), 3000)
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const navigate = useNavigate();
  const { pairDetails = {}, marketList: markets = [], favPair, unFavourite } = props;
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [listMarkets, setListMarkets] = useState([]);
  const [searchVal, setsearchVal] = useState("");
  const [fliteredPairList, setFilteredPairList] = useState([]);
  const [quoteData, setQuoteData] = useState([])
  function setCurrency(currency) {
    if (currency != selectedCurrency) {
      setSelectedCurrency(currency);
    }
  };


  useEffect(() => {
    if (props.toCurrencySymbol != selectedCurrency && selectedCurrency == "") {
      setSelectedCurrency(props.toCurrencySymbol);
    }
  }, [props]);

  useEffect(() => {
    if (selectedCurrency) {
      const idx = markets.findIndex(
        (elem) => elem.currency == selectedCurrency
      );
      if (idx > -1) {
        setListMarkets(markets[idx].pairs);
      } else {
        setListMarkets([]);
      }
    }
  }, [selectedCurrency, markets, listMarkets]);

  useEffect(() => {
    const searchQuery = searchVal.toLowerCase();

    const data = listMarkets.filter((data) => {
      const pair = data.pair.split("_").join("").toLowerCase();
      if (!searchQuery) {
        return true
      } else {
        return pair.includes(searchQuery)
      }
    })
    setFilteredPairList(data)
  }, [listMarkets, selectedCurrency, searchVal]);

  useEffect(() => {
    const data = selectedCurrency == "Fav"
      ? markets.filter((data) => data.currency == "Fav")
      : markets.filter((data) => data.currency !== "Fav");
    setQuoteData(data)
  }, [selectedCurrency, markets]);




  return (
    <div>
      <div>
        <div className=" ">
          <div className="d-flex flex-lg-row flex-wrap   overflow-scroll-styling-css gap-lg-0 trading-page-top-section-1 align-items-center ps-2  ">
            <div className="d-flex flex-row align-items-center">
              <div className=" trading-page-section-1">
                <div className="spot-page-pair-select-dropdown" ref={dropdownRef}>
                  <div className="dropdown ">
                    <div className="dropdown1">
                      <button
                        className="dropbtn1 px-0 d-flex align-items-center"
                        onClick={ToggleClass}
                      >
                        {pairDetails &&
                          pairDetails.pair &&
                          showPairName(pairDetails.pair)}
                        {isActive === true ? (
                          <FiChevronUp className="ms-2" />
                        ) : (
                          <FiChevronDown className="ms-2" />
                        )}
                      </button>
                      <div
                        className={
                          isActive === false
                            ? "dropdown-content1"
                            : " dropdown-content1 dropdown-content1-onlick "
                        }
                      >
                        <span className="top-new-spot-ser-csv-section">
                          <div className="new-spot-ser-csv-1">
                            <div class="input-group mb-1">
                              <span class="input-group-text" id="basic-addon1"><FaSearch /></span>
                              <input type="text"
                                class="form-control"
                                placeholder="Search"
                                value={searchVal}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[a-zA-Z0-9]*$/;
                                  if (regex.test(value)) {
                                    setsearchVal(value);
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="new-spot-ser-csv-2">
                            <ul class="nav nav-pills gap-2" id="pills-tab" role="tablist">
                              <li class="nav-item" role="presentation">
                                <button
                                  class="nav-link"
                                  id="pills-fav-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-fav"
                                  type="button" role="tab"
                                  aria-controls="pills-fav"
                                  aria-selected="true"
                                  onClick={() => {
                                    $("#pills-hot-tabFav").click();
                                    setCurrency("Fav");
                                  }}
                                >
                                  Favourite
                                </button>
                              </li>
                              <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-spot-tab" data-bs-toggle="pill" data-bs-target="#pills-spot" type="button" role="tab" aria-controls="pills-spot" aria-selected="false" onClick={() => setCurrency("USDT")}>Spot</button>
                              </li>
                            </ul>
                          </div>

                          <div class="tab-content mt-2" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-spot" role="tabpanel" aria-labelledby="pills-spot-tab" tabindex="0">
                              <div className="new-spot-ser-csv-3">
                                <ul class="nav nav-pills gap-1" id="pills-tab" role="tablist">
                                  {
                                    quoteData &&
                                    quoteData.length > 0 &&
                                    quoteData.map((market, idx) => {
                                      return (
                                        <li class="nav-item" role="presentation" onClick={() => setCurrency(market.currency)}>
                                          <button class="nav-link" id={`pills-hot-tab${market.currency}`} data-bs-toggle="pill" data-bs-target="#pills-hot" type="button" role="tab" aria-controls="pills-hot" aria-selected="false" onClick={() => market.currency !== "Fav" && $("#pills-spot-tab").click()}>{market.currency}</button>
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </div>
                              <div className="new-spot-ser-csv-4">
                                <Pairtable
                                  getFavPairList={props.getFavPairList}
                                  searchVal={searchVal}
                                  fliteredPairList={fliteredPairList}
                                  showPairName={showPairName}
                                  favPair={favPair}
                                  decimalValue={props.decimalValue}
                                  ToggleClass={ToggleClass}
                                  selectedCurrency={selectedCurrency}
                                />
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="trading-page-section-1">
              <span className={`trade-text-4 ${marketPriceColor}`}  >

                {pairDetails && pairDetails.marketPrice
                  ? props.decimalValue(
                    pairDetails.marketPrice,
                    pairDetails.priceDecimal,
                    "removeZero"
                  )
                  : 0}
              </span>
              <p className="trade-text-7 mt-1">
                ${pairDetails && pairDetails.usdPrice > 0 ? props.decimalValue(
                  pairDetails.usdPrice,
                  pairDetails.priceDecimal,
                  "removeZero"
                ) : '-'}
              </p>
            </div>
            <div className=" trading-page-section-1 ps-lg-4">
              <p className="trade-text-6">24h High</p>
              <p className="trade-text-7">
                {pairDetails && pairDetails.pair != undefined
                  ? props.decimalValue(
                    pairDetails.high,
                    pairDetails.totalDecimal,
                    "removeZero"
                  )
                  : "-"}
              </p>
            </div>
            <div className=" trading-page-section-1">
              <p className="trade-text-6">24h Low</p>
              <p className="trade-text-7">
                {pairDetails && pairDetails.pair != undefined
                  ? props.decimalValue(
                    pairDetails.low,
                    pairDetails.totalDecimal,
                    "removeZero"
                  )
                  : "-"}
              </p>
            </div>
            <div className="trading-page-section-1">
              <p className="trade-text-6">
                24h Vol(
                {pairDetails.fromCurrency &&
                  pairDetails.fromCurrency.currencySymbol}
                )
              </p>
              <p className="trade-text-7">
                {pairDetails && pairDetails.volume_fromCur != undefined
                  ? props.decimalValue(
                    pairDetails.volume_fromCur,
                    pairDetails.totalDecimal
                  )
                  : "0.00"}
              </p>
            </div>
            <div className=" trading-page-section-1">
              <p className="trade-text-6">
                24h Vol(
                {pairDetails.toCurrency &&
                  pairDetails.toCurrency.currencySymbol}
                )
              </p>
              <p className="trade-text-7">
                {pairDetails && pairDetails.volume_toCur != undefined
                  ? props.decimalValue(pairDetails.volume_toCur, pairDetails.totalDecimal)
                  : "0.00"}
              </p>
            </div>
            <div className=" trading-page-section-1 border-0">
              <p className="trade-text-6">24h Change</p>
              <p className="trade-text-7 fc-r">
                {pairDetails && pairDetails.pair != undefined ? (
                  <>
                    <span
                      className={
                        pairDetails.change > 0 ?
                          "color-green"
                          : pairDetails.change == 0 ?
                            "text-light"
                            : "color-darkpink"
                      }
                    >
                      {(pairDetails.change >= 0 ? "+" : "") + props.decimalValue(pairDetails.change, pairDetails.totalDecimal)}
                    </span>&nbsp;&nbsp;&nbsp;
                    <span
                      className={
                        pairDetails.changePer > 0 ?
                          "color-green"
                          : pairDetails.changePer == 0 ?
                            "text-light"
                            : "color-darkpink"
                      }
                    >
                      {props.decimalValue(pairDetails.changePer, 2)}%
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
