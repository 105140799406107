import React from 'react'

function Footer() {
    return (
        <div className='home-dfd-footer-sec nexchange-footer-section'>
            <div className='container-fluid col-lg-10'>
                <div className='row g-4 justify-content-between'>
                    <div className='col-lg-3'>
                        <h5 className='mb-5'>BitNevex</h5>
                        <p className='dfd-footer-txt-1'>Through many of its unique properties, Bitcoin allows exciting uses that could not be covered by any previous payment system.</p>
                    </div>
                    <div className='col-lg-2'>
                        <h5 className='mb-5 dfd-footer-txt-2'>IMPORTANT LINKS</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a href=''>About Us</a>
                            <a href=''>Contact Us</a>
                            <a href=''>Agent Contact</a>
                            <a href=''>Services</a>
                        </div>
                    </div>
                    <div className='col-lg-2'>
                        <h5 className='mb-5 dfd-footer-txt-2'>Resources</h5>
                        <div class="list-group footer-typo footer-typo-mb">
                            <a href=''>Prices</a>
                            <a href=''>Tax</a>
                            <a href=''>Blog</a>
                            <a href=''>Support</a>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <h5 className='mb-md-5 dfd-footer-txt-2'>Get in Touch</h5>
                        <p className='dfd-footer-txt-1 mb-md-5'>Question or feedback? We’d love to hear from you.</p>
                        <div >
                            <input type="email" class="form-control" placeholder="Your email" />
                        </div>
                    </div>
                </div>
                <div className='row g-4 justify-content-between mt-4'>
                    <div className='col-lg-6 text-md-start text-center'>
                        <p className='dfd-footer-txt-1'>Copyright © BitNevex 2024</p>
                    </div>
                    <div className='col-lg-6'>
                        <div className='d-flex gap-3 justify-content-md-end justify-content-center'>
                        <p className='dfd-footer-txt-1'>Privacy Policy</p>
                        <p className='dfd-footer-txt-1'>Tearms & Conditions</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
