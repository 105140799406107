import React, { useRef, useEffect } from 'react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import One from "../../../assets/Nexchange-images/moonoutline.png";
import Two from "../../../assets/Nexchange-images/star.png";

gsap.registerPlugin(ScrollTrigger);

function Moon() {
    const main = useRef();

    useEffect(() => {
        const boxes = gsap.utils.toArray(".top-img-1");
        const boxes2 = gsap.utils.toArray(".floating-glow");

        if (window.innerWidth > 992) {
            boxes.forEach((box) => {
                gsap.to(box, {
                    x: 240,
                    y: -260,
                    opacity: 1,
                    duration: 3, // Slower transition duration
                    ease: "power2.inOut", // Smoother easing function
                    scrollTrigger: {
                        trigger: box,
                        start: "bottom bottom",
                        end: "top 68%",
                        scrub: true,
                        markers: false,
                    },
                });
            });
            boxes2.forEach((box) => {
                gsap.to(box, {
                    opacity: 1,
                    duration: 3, // Slower transition duration
                    ease: "power2.inOut", // Smoother easing function
                    scrollTrigger: {
                        trigger: box,
                        start: "bottom 70%",
                        end: "top 0%",
                        scrub: true,
                        markers: false,
                    },
                });
            });
        }
    }, []);
    return (
        <div ref={main}>
            <div className='container-fluid '>
                <div className='row moon-sec-height align-items-center'>
                    <div className='col text-center d-flex justify-content-center'>
                        <div className='position-relative d-flex'>
                            <div className='floating-glow'></div>
                            <div className='top-img-1'>
                                <LazyLoadImage
                                    alt="one"
                                    src={Two}
                                />
                            </div>
                            <div className='top-img-2'>
                                <LazyLoadImage
                                    alt="Two"
                                    src={One}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Moon;