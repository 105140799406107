import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function Sun() {
    const moonRef = useRef(null);
    const sunblurRef = useRef(null);
    const sunRef = useRef(null);

    useEffect(() => {
        // Set initial position and box-shadow for moon
        if (window.innerWidth > 992) {
            gsap.set(moonRef.current, { x: -300, boxShadow: 'none' });

            // Animate moon's position and box-shadow
            gsap.to(moonRef.current, {
                scrollTrigger: {
                    trigger: moonRef.current,
                    start: 'top center',
                    end: '+=300',
                    scrub: true,
                },
                x: -240,
            });

            // Animate sunblur's position and opacity
            gsap.to(sunblurRef.current, {
                scrollTrigger: {
                    trigger: sunblurRef.current,
                    start: 'top center',
                    end: '+=300',
                    scrub: true,
                },
                x: -30,
                opacity: 1,
            });

            // Animate sun's background color and box-shadow
            gsap.to(sunRef.current, {
                scrollTrigger: {
                    trigger: sunRef.current,
                    start: 'top center',
                    end: '+=300',
                    scrub: true,
                },
                backgroundColor: '#fff1d2',  // White color
                boxShadow: '0 0 70px 30px rgb(83 0 255 / 56%),  0 0 100px 50px rgb(185, 130, 255)',
                borderRadius: '50%',
                filter: "blur(5px)"
            });
        }
    }, []);

    return (
        <div className='sunmoon-animation-section'>
            <div className="sun-moon">
                <div className="sun" ref={sunRef}></div>
                <div className="moon" ref={moonRef}></div>
                <div className="sunblur" ref={sunblurRef}></div>
            </div>
        </div>

    );
};