import React, { useEffect, useState } from "react";
import { AiOutlineSend } from "react-icons/ai"
import { dateFormat, showEmail } from '../../core/helper/date-format';
import { Button, Card } from 'react-bootstrap'
import jQuery from "jquery";
import { FaUserAlt } from 'react-icons/fa';
import axios from "axios";
import { GrAttachment } from "react-icons/gr";
import { RiAttachment2 } from "react-icons/ri";

export default function SupportChatbox(props) {
    const {
        myProfile,
        description,
        handleChattingChange,
        ticketChat,
        chatisLoading,
        submit,
        setTicketId,
        chatHistory,
        selectFiles,
        setchatattachment,
        setchangefile,
        errorMessage,
        setErrorMessage,
        isChat
    } = props;
    const [fileName, setFileName] = useState("");
    const handleFileSelect = ({ name, size, source, file }) => {
        const url = URL.createObjectURL(file);
        console.log('url', url)
        setchatattachment(file);
        setchangefile(url);
        setFileName(file.name);
        setErrorMessage("");
    };

    const handleSubmit = () => {
        if (ticketChat.trim() === '' && fileName === '') {
            setErrorMessage("Message or attachment is required");
            return;
        }
        submit();
        setFileName('');
    };

    return (
        <>
            <Card className="p-3">
                <Card.Body>
                    <div className="row">
                        <div className="col">
                            <div className="float-start">
                            </div>
                            {
                                chatHistory && chatHistory.length > 0 && chatHistory.map((data) => {
                                    return (
                                        data.userType == "user" ?
                                            <div className="float-start col-12 mb-2 d-flex flex-row gap-2">
                                                <h5 className="sup-tic-t-1">{myProfile.email.charAt(0)}</h5>
                                                <div className="sup-tic-t-2 d-flex flex-column">
                                                    <p className="mb-0">{data.message}</p>
                                                    {

                                                        data.chattingImage ?
                                                            <>
                                                                <a className="" style={{ right: "4px" }} target="_blank" href={data.chattingImage}>
                                                                    <RiAttachment2 />
                                                                </a>
                                                                <img src={data.chattingImage} style={{ height: "40px", width: "40px" }} />
                                                            </>
                                                            :
                                                            ''
                                                    }
                                                    <span className='support-ticket-date'>Date:{dateFormat(data.createdDate)}</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="text-end col-12 mb-2 d-flex flex-row gap-2 flex-row-reverse">
                                                <h5 className="sup-tic-t-1">A</h5>
                                                <div className="sup-tic-t-2 d-flex flex-column">
                                                    <p className="">{data.message}</p>
                                                    {
                                                        data.chattingImage ?
                                                            <>
                                                                <a className="" style={{ left: "4px" }} target="_blank" href={data.chattingImage}>
                                                                    <RiAttachment2 />
                                                                </a>
                                                                <img src={data.chattingImage} style={{ height: "40px", width: "40px" }} />
                                                            </>
                                                            :
                                                            ''
                                                    }
                                                    <span className='support-ticket-date'>Date:{dateFormat(data.createdDate)}</span>
                                                </div>
                                            </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Card.Body>
            </Card>
            {isChat == 1 && (
                <div className="mt-3 sup-tic-t-send">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                    }}>
                        {
                            fileName &&
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    value={fileName}
                                />
                            </div>
                        }

                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                aria-label="Dollar amount (with dot and two decimal places)"
                                name="chatting"
                                id="chatting"
                                autoComplete='off'
                                placeholder="Type something"
                                disabled={isChat == 1 ? false : true}
                                onChange={(e) => {
                                    handleChattingChange(e);
                                    setErrorMessage("");
                                }}
                                value={ticketChat}
                            />
                            <button className={"input-group-text" + (chatisLoading == false ? " curPointer" : "")} disabled={isChat == 1 ? false : true} onClick={handleSubmit} type="submit"><AiOutlineSend /></button>
                            <button className="input-group-text" style={{ backgroundColor: "#5928E98c" }} disabled={isChat == 1 ? false : true} onClick={() => selectFiles({ accept: "image/*" }, handleFileSelect)}> <RiAttachment2 /> </button>
                        </div>
                        {errorMessage && <div className="text-danger mt-1">{errorMessage}</div>}
                    </form>
                </div>
            )}

        </>
    )
}