import { AESDecrypt } from "../../helper/AESDecrypt";
import { JWTDecode } from "../../helper/JWTDecode"
export const responseChanges = (data) => {
    if (typeof data === "object") {
        const {
            message,
            error,
            status = false
        } = data;
        if (message && message === 'Data retrived successfully') {
            delete data.message;
        }
        if (status) {
            data.toastType = "success";
        }
        else {
            data.toastType = "error";
        }
        if (typeof error === 'undefined') { data.error = {}; }
        return data;
    } else {
        let decodedData = AESDecrypt(data);
        const tokenData = JWTDecode(decodedData);

        const {
            message,
            error,
            status = false
        } = tokenData;
        if (message && message === 'Data retrived successfully') {
            delete tokenData.message;
        }
        if (status) {
            tokenData.toastType = "success";
        }
        else {
            tokenData.toastType = "error";
        }
        if (typeof error === 'undefined') { tokenData.error = {}; }
        return tokenData;
    }
}