import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ImKey } from "react-icons/im";
import Modal from 'react-bootstrap/Modal';
import { toast } from "../../core/lib/toastAlert";
import Config from "../../core/config/index"
import { makeRequest } from "../../core/services/v1/request";
import { TbZoomQuestion } from "react-icons/tb";
import { FaCopy } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { useContextData } from "../../core/context/index";
import { useNavigate } from "react-router-dom";

export default function GenerateApi() {
    const { myProfile } = useContextData();
    const [modelShow, setModelShow] = useState(false);
    const [verifyCode, setVerifyCode] = useState();
    const [userKeys, setUserKeys] = useState([]);
    const [secterModal, setSecretModel] = useState(false);
    const [showSecretKey, setShowSecretKey] = useState(false)
    const Navigate = useNavigate();
    const handleCopyClick = (id, idType) => {
        toast({ type: 'success', message: `${idType} Copied!` })
        navigator.clipboard.writeText(id)
    };
    const column = [
        {
            id: 0,
            name: "Sl.No",
            selector: (row, index) => index + 1
        }, {
            id: 1,
            name: "User Id",
            selector: (row) => (
                <>
                    {row._id}&nbsp;&nbsp;&nbsp;
                    <span>
                        <FaCopy className="cursor-poniter" onClick={() => handleCopyClick(row._id, "userId")} />
                    </span>
                </>
            )

        }, {
            id: 2,
            name: "Api Key",
            selector: (row) => (
                <>
                    {row.apiKey}&nbsp;&nbsp;&nbsp;
                    <span>
                        <FaCopy className="cursor-poniter" onClick={() => handleCopyClick(row.apiKey, "ApiKey")} />
                    </span>
                </>
            )

        }, {
            id: 3,
            name: "View",
            selector: (row) => <TbZoomQuestion className="cursor-poniter" onClick={() => setSecretModel(true)} />
        }
    ]
    const generateKey = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                otp: verifyCode
            }
            const params = {
                url: `${Config.V3_API_URL}bsinessApi/genApiAndSecKey`,
                method: "post",
                data: payload
            }
            if (verifyCode && verifyCode !== undefined && verifyCode !== null && verifyCode != "") {
                const res = await makeRequest(params);
                if (res.status) {
                    toast({ type: "success", message: res.message });
                    getKeys()
                    setVerifyCode();
                    setModelShow(false);
                } else {
                    toast({ type: "error", message: res.message });
                }
            } else {
                toast({ type: "error", message: "Please Enter Your 2FA Code!" });
            }
        } catch (error) {
            toast({ type: "error", message: "Something Went Wrong!" });
        }
    }
    const showUserSecretKey = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                otp: verifyCode
            }
            const params = {
                url: `${Config.V3_API_URL}bsinessApi/verify-secretKey`,
                method: "post",
                data: payload
            }
            if (verifyCode && verifyCode !== undefined && verifyCode !== null && verifyCode != "") {
                const res = await makeRequest(params);
                if (res.status) {
                    toast({ type: "success", message: res.message });
                    setShowSecretKey(true);
                } else {
                    toast({ type: "error", message: res.message });
                }
            } else {
                toast({ type: "error", message: "Please Enter Your 2FA Code!" });
            }
        } catch (error) {
            toast({ type: "error", message: "Something Went Wrong!" });
        }
    };

    const getKeys = async () => {
        const params = {
            url: `${Config.V3_API_URL}bsinessApi/getApiAndSecret`,
            method: "get",
        }
        const res = await makeRequest(params);
        if (res.status) {
            if (res.data.apiKey !== "" && res.data.secretKey !== "") {
                setUserKeys([res.data])
            }
        } else {
            toast({ type: "error", message: res.message });
        }
    }
    useEffect(() => {
        getKeys();
        if (myProfile?.tfaenablekey == "") {
            toast({ type: 'error', message: "Please Enable 2FA Auth" });
            setTimeout(() => Navigate("/google-authenticator"), 2000)
        };
    }, []);

    const styles = {
        headCells: {
            style: {
                justifyContent: "center",
                alignItems: "center"
            }
        },
        cells: {
            style: {
                justifyContent: "center",
                alignItems: "center"
            }
        }
    };
    return (<>
        <div className="container">
            <div className="row mt-5 g-3">
                <div className="col-lg-9">
                    <p className="h2">API Management</p>
                    Get-X provides powerful APIs! Aimed at helping you trade or withdraw efficiently.View API documentation details.
                </div>
                <div className="col-lg-3">
                    {
                        myProfile?.tfaenablekey != "" ? (
                            <>
                                <button type="button" className="btn text-white" style={{backgroundColor:"var(--btnClr)"}} onClick={() => setModelShow(true)}>
                                    <span><ImKey /> </span>&nbsp;Generate
                                </button>
                            </>
                        ) : (
                            <button type="button" className="btn text-white" style={{backgroundColor:"var(--btnClr)"}} onClick={() => {
                                toast({ type: 'error', message: "Please Enable 2FA Auth" });
                                setTimeout(() => Navigate("/google-authenticator"), 2000)
                            }}>
                                <span><ImKey /> </span>&nbsp;Generate
                            </button>
                        )
                    }
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <DataTable
                        persistTableHead
                        columns={column}
                        data={userKeys}
                        customStyles={styles}
                    />
                </div>
            </div>

            <Modal
                show={modelShow}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={true}
                centered
                onHide={() => {
                    setModelShow(false);
                }}
            >
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row  mt-0 mb-3 mx-lg-5">
                            <label className="mb-1 p-0" style={{ fontSize: "13px" }}>Enter Your 2FA Code...</label>
                            <input className="gen-api-inpt" placeholder="6 digit code" type="text" value={verifyCode} onChange={(e) => {
                                const numRegex = /^[0-9]+$/;
                                const isTrue = numRegex.test(e.target.value);
                                if (isTrue) {
                                    setVerifyCode(e.target.value);
                                } else {
                                    toast({ type: "error", message: "Number Only Allow This Field!" });
                                    setVerifyCode("")
                                }
                            }} />
                        </div>
                        <div className="row mt-2 mb-2 mx-lg-5">
                            <button onClick={(e) => generateKey(e)} className="btn text-white" style={{ backgroundColor: "var(--btnClr)" }}>Generate</button>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>

            <Modal
                show={secterModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={true}
                centered
                onHide={() => {
                    setSecretModel(false);
                    setShowSecretKey(false);
                    setVerifyCode("")
                }}
            >
                <Modal.Header closeButton className="border-0">
                </Modal.Header>
                <Modal.Body>

                    {!showSecretKey && (
                        <form>
                            <div className="row  mt-0 mb-3 mx-lg-5">
                                <label className="mb-1 p-0" style={{ fontSize: "13px" }}>Enter Your 2FA Code...</label>
                                <input className="gen-api-inpt" placeholder="6 digit code" type="text" value={verifyCode} onChange={(e) => {
                                    const numRegex = /^[0-9]+$/;
                                    const isTrue = numRegex.test(e.target.value);
                                    if (isTrue) {
                                        setVerifyCode(e.target.value);
                                    } else {
                                        toast({ type: "error", message: "Number Only Allow This Field!" });
                                        setVerifyCode("")
                                    }
                                }} />
                            </div>
                            <div className="row mt-2 mb-2 mx-lg-5">
                                <button onClick={(e) => showUserSecretKey(e)} className="btn text-white" style={{ backgroundColor: "var(--btnClr)" }}>Verify</button>
                            </div>
                        </form>
                    )}

                    {showSecretKey && (<>
                        <div className="container mt-2 mb-3">
                            <div className="row">
                                <div>Your Get-X SecretKey: </div>
                            </div>
                            <div className="row row mt-2 mb-2 gen-api-inpt">
                                <div>
                                    <p style={{ fontSize: "13px" }} title="Copy" className="mb-0"> {userKeys[0].secretKey}
                                        <span className="cursor-poniter ms-1"><FaCopy onClick={() => handleCopyClick(userKeys[0].secretKey, "Secret Key")} /></span></p>
                                </div>
                            </div>
                        </div>
                    </>)}
                </Modal.Body>
            </Modal>
        </div>
    </>)
};