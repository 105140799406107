import React, { useState, useEffect } from "react";
import "../assets/style-coin-list.css";
import NavbarOne from "../Pages/siteTheme/NavbarOne";
import Footer from "../Pages/siteTheme/Footer";
import { FaArrowRight } from "react-icons/fa6";
import Newlock from "../assets/images/coin-listing/Newlockimg.png"
import Rocketimg from "../assets/images/coin-listing/Rocket.png"
import Bitcoin from "../assets/images/coin-listing/Bitcoin.png"
import Turncoin from "../assets/images/coin-listing/Turnbitcoin.png"
import { FaArrowCircleRight } from "react-icons/fa";
import { LuPlusCircle } from "react-icons/lu";
import { AiFillExclamationCircle } from "react-icons/ai";
import Select from 'react-select';
import { FaFacebook } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaArrowCircleLeft } from "react-icons/fa";
import Getxlogo from "../../src/assets/images/getxlogo.png"
import countryList from 'react-select-country-list'
import { useMemo } from 'react'
import { useNavigate } from "react-router-dom";
import { Country } from "country-state-city";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import phone from 'phone';
import { RxCrossCircled } from "react-icons/rx";
import { SocialIcon } from 'react-social-icons'
import Whatsappicon from "../../src/assets/images/coin-listing/whatsapp-icon.png"
import { useContextData } from '../core/context/index';

export default function Withdraw(props) {
    const navigate = useNavigate();
    const options = [
        { value: '1', label: <><img src={Whatsappicon} className="social-icons me-2" /> Whatsapp</> },
        { value: '2', label: <><SocialIcon network="telegram" className="social-icons me-2" /> Telegram</> },
        { value: '3', label: <><SocialIcon network="facebook" className="social-icons me-2" /> Facebook</> },
        { value: '4', label: <><SocialIcon network="x" className="social-icons me-2" /> Twitter</> },
    ];
    const [value, setValue] = useState('')
    const option = useMemo(() => countryList().getData(), [])
    const [coinName, setCoinName] = useState("");
    const [contact, setContact] = useState([{ network: "1", contactNumber: "" }])
    const [hideButton, setHideButton] = useState(false);
    const [coinNameErr, setCoinNameErr] = useState("");
    const [numberErr, setNumberErr] = useState([]);
    const [selectedNetworks, setSelectedNetworks] = useState(["1"]);
    const availableOptions = useMemo(() => {
        return options.filter(option => !selectedNetworks.includes(option.value));
    }, [selectedNetworks]);
    const { ipAddress } = useContextData();
    const [vpnCountry, setVpnCountry] = useState('');

    const changeHandler = value => {
        setValue(value)
    }

    useEffect(() => {
        const fetchVpnCountry = async () => {
            try {
                setVpnCountry(ipAddress.country_name);
            } catch (error) {
                console.error('Error fetching VPN country', error);
            }
        };

        fetchVpnCountry();
    }, [ipAddress]);

    const addInput = () => {
        const findEmpty = contact.filter(element => element.network === "" || element.contactNumber === "");
        if (findEmpty.length === 0) {
            if (contact.length < 4 && availableOptions.length > 0) {
                const newNetwork = availableOptions[0].value;
                setSelectedNetworks([...selectedNetworks, newNetwork]);
                setContact([...contact, { network: newNetwork, contactNumber: "" }]);
                setNumberErr([...numberErr, ""]); // Add an empty error for the new contact field
                if (contact.length === 3) {
                    setHideButton(true);
                }
            }
        } else {
            let errors = contact.map((el, idx) => (el.network === "" || el.contactNumber === "") ? "Please fill the fields" : "");
            setNumberErr(errors);
        }
    };
      

    const removeInput = (index) => {
        let updatedContacts = [...contact];
        let updatedErrors = [...numberErr];
        let updatedNetworks = [...selectedNetworks];

        updatedNetworks.splice(index, 1);
        updatedContacts.splice(index, 1);
        updatedErrors.splice(index, 1);

        setContact(updatedContacts);
        setNumberErr(updatedErrors);
        setSelectedNetworks(updatedNetworks);

        if (updatedContacts.length < 4) {
            setHideButton(false);
        }
    };

    const handleChange = (event, index) => {
        let updatedContacts = [...contact];
        let errors = [...numberErr];

        if (event && event.target) {
            const { name, value } = event.target;
            updatedContacts[index][name] = value;

            if (name === "contactNumber") {
                errors[index] = validateContact(updatedContacts[index].network, value) ? "" : getErrorMessage(updatedContacts[index].network);
            } else {
                errors[index] = "";
            }

        } else if (event && event.value) {
            updatedContacts[index]["network"] = event.value;
            updatedContacts[index]["contactNumber"] = ""; // Clear contact number when network changes
            errors[index] = ""; // Clear error message for the current field
        } else if (event) {
            const phoneDetail = phone(event, { country: vpnCountry });
            if (!phoneDetail.isValid) {
                errors[index] = "Invalid phone number, Please enter correct phone number";
            } else {
                updatedContacts[index]["contactNumber"] = event;
                errors[index] = "";
            }
        }

        setContact(updatedContacts);
        setNumberErr(errors);

        const selectedNetworks = updatedContacts.map(contact => contact.network);
        setSelectedNetworks(selectedNetworks);
    };

    const getErrorMessage = (network) => {
        switch (network) {
            case "1":
                return "Invalid phone number";
            case "2":
                return "Invalid Telegram username or URL";
            case "3":
                return "Invalid Facebook URL";
            case "4":
                return "Invalid Twitter URL or Username";
            default:
                return "Invalid contact information";
        }
    };

    const validateContact = (network, contactNumber) => {
        if (network === "1") { // Whatsapp validation
            const phoneDetail = phone(contactNumber, { country: vpnCountry });
            // console.log("phoneDetail", phone(contactNumber, { country: "" }));
            return phoneDetail.isValid;
        } else if (network === "2") { // Telegram validation
            const telegramPattern = /^(https?:\/\/)?(www\.)?(t\.me\/|telegram\.me\/)?(@)?[a-zA-Z0-9_]{5,32}$/;
            return telegramPattern.test(contactNumber);
        } else if (network === "3") { // Facebook validation
            const facebookPattern = /^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/[a-zA-Z0-9(\.\?)?]/;
            return facebookPattern.test(contactNumber);
        } else if (network === "4") { // Twitter validation
            const twitterPattern = /^(https?:\/\/)?(www\.)?(x)\.com\/[a-zA-Z0-9_]{1,15}$/;
            const twitterUsernamePattern = /^@[a-zA-Z0-9_]{1,15}$/;
            return twitterPattern.test(contactNumber) || twitterUsernamePattern.test(contactNumber);
        }
        return false;
    };


    // <========================>
        const customStyles = {
            menu: base => ({
                ...base,
                background: "#FFF",
                margin: "0px",
                padding: "0px",
            }),
            menuList: (provided, state) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0,
            }),
            control: (base, state) => ({
                ...base,
                background: "rgb(43, 58, 119)",
                color: "white",
                borderColor: state.isFocused ? "#00e5ff21" : "#00e5ff21",
                cursor: "pointer",
                borderRadius: "2px",
                borderColor: state.isFocused ? "transparent" : "transparent",
                boxShadow: state.isFocused ? null : null,
                "&:hover": {
                    borderColor: state.isFocused ? "transparent" : "transparent",
                }
            }),
            option: (base, { isDisabled, isFocused }) => ({
                ...base,
                backgroundColor: isFocused ? "rgb(19 34 90)" : "rgb(138 153 235)",
                color: isFocused ? "white" : "black",
                cursor: "pointer",
                '&:active': {
                    background: 'rgb(19 34 90)',
                },
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                display: 'none',
            }),
            singleValue: (base) => ({
                ...base,
                color: '#FFF',
            }),
            multiValue: (base) => ({
                ...base,
                color: '#FFF',
            }),
            input: (base) => ({
                ...base,
                color: '#FFF',
            }),
            dropdownIndicator: (base) => ({
                ...base,
                color: '#FFF',
                '&:hover': {
                    color: '#FFF',
                },
            }),
        };
        

    const create = () => {
        let errors = contact.map((el, idx) => {
            if (el.network === "" || el.contactNumber === "") {
                return "Please fill the fields";
            } else if (!validateContact(el.network, el.contactNumber)) {
                return el.network === "1" ? "Invalid phone number" : el.network === "2" ? "Invalid telegram Id (or) url" : "Invalid URL";
            } else {
                return "";
            }
        });

        if (errors.some(error => error !== "") || coinName === "") {
            setCoinNameErr(coinName === "" ? "Please fill the fields" : "");
            setNumberErr(errors);
        } else {
            setCoinNameErr("");
            setNumberErr([]);
            let payload = { contact, coinName };
            navigate("/coin-form", { state: { data: payload } });
            window.location.reload();
        }
    };

    return (
        <div className="coinlist">
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className="">
                {/* <=========> */}
                <section className="flow-first-ban-sec-01">
                    <div className="gtex-whl-fst-sec">
                        <p className="grn-blu-clr-01"></p>
                        <div className="container">
                            <div className="row neeeew min-vh-100 d-flex justify-content-center align-items-center">
                                <div className="col-lg-6 ">

                                </div>
                                <div className="col-lg-6 gtex-ban-child-sec">
                                    <div className="gtex-bann-ctn-sec">
                                        <span className=""></span>
                                        <h2 className="ban-list-ctn-01"><span className="affiliate-text-1-1">List Your </span>Coin on Getx</h2>
                                        <p className="banner-text">Raise your cryptocurrency's visibility and utility by listing it on our expansive global exchange, to gain access to a worldwide market access, and extensive community reach.</p>
                                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="list-getx-btn">List on Getx Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {/* <========> */}
                {/* <=============> */}
                <section className="sec-whl-box-01 py-lg-5 py-3">
                    <div className="container py-3">
                        <div className="row justify-content-center g-4 align-items-center flex-lg-row-reverse">
                        <div className="col-lg-6">
                                <img src={Newlock} className="newlock-img" />
                            </div>
                            <div className="col-lg-6">
                                <div className="sec-lft-ctn-01">
                                    <h2 className="fw-bold">What is Getx?</h2>
                                    <p>Getx, India's top crypto exchange, provides a secure platform for Bitcoin and crypto trading. Our centralized system ensures user data and funds are protected with encryption and multiple security protocols.</p>
                                    <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="list-getx-btn">List on Getx Now</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                {/* <=================> */}
                <div className="new-asdsad pb-5">
                    <section className='asdsad container  position-relative '>
                        <div className="row mb-4 text-center ">
                            <div className="col-lg-12 mb-lg-4 mt-lg-4">
                                <h2 className="fw-bold pt-lg-5">Why Choose Getx to List Your Token/Crypto?
                                </h2>
                            </div>
                            <p className="new-blu-round-01"></p>
                            <p className="new-blu-round-02"></p>
                        </div>
                        <div class="row g-3">
                            <div class="col-lg-4 mb-4">
                                <div class="card h-100 mt-lg-4">
                                    <div class="card-body">
                                        <div className='asdsad2w32 alt-asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 my-lg-4 my-2 glow-card-sec-01">A Large User-base</h5>
                                        <p class="card-text alt-new-large-ctn">A huge number of cryptocurrency users are engaged on Getx a growing crypto exchange platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-4">
                                <div class="card h-100 ">
                                    <div class="card-body ">
                                        <div className='asdsad2w32 alt-asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 my-lg-4 my-2 glow-card-sec-01">Distinct Admin Panel</h5>
                                        <p class="card-text alt-new-large-ctn">The Admin Panel simplifies asset management, tracking deposits, withdrawals, trade history, and active trades.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-4">
                                <div class="card h-100 mt-lg-4">
                                    <div class="card-body">
                                        <div className='asdsad2w32 alt-asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 my-lg-4 my-2 glow-card-sec-01">Multi-layer Security</h5>
                                        <p class="card-text alt-new-large-ctn">Our exchange’s tamper-proof security makes sure the funds are safe.  It includes strong encryption and constant monitoring.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card h-100 mt-lg-4">
                                    <div class="card-body">
                                        <div className='asdsad2w32 alt-asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 my-lg-4 my-2 glow-card-sec-01">24/7 Support</h5>
                                        <p class="card-text alt-new-large-ctn">Our team offers quick issue resolution for coin owners through email, chat, call, and tickets.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card h-100 ">
                                    <div class="card-body">
                                        <div className='asdsad2w32 alt-asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 my-lg-4 my-2 glow-card-sec-01">Custom Marketing Methods</h5>
                                        <p class="card-text alt-new-large-ctn">We offer customized premium marketing activities to connect you with the crypto community, based on your chosen package.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card h-100 mt-lg-4">
                                    <div class="card-body">
                                        <div className='asdsad2w32 alt-asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 my-lg-4 my-2 glow-card-sec-01">Advanced Affiliate Programme
                                        </h5>
                                        <p class="card-text alt-new-large-ctn">Coin and token owners can earn 60% of trading fees by referring users to Getx to trade cryptocurrencies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center gtex-bann-ctn-sec mt-5">
                            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="list-getx-btn">List on Getx Now</button>
                        </div>

                    </section>
                </div>


                <section className="fifth-card-sec-001 position-relative pt-0 pb-5">
                    <div className="container py-5">
                        <p className="blu-circle-clr"></p>
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2 className="pb-5 fw-bold">Reasons to List Your Token on the INR Market?</h2>
                            </div>
                        </div>
                        <div class="row  g-3 d-flex justify-content-center gtex-bann-ctn-sec-001">
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="card h-100 alt-new-card-sec-01">
                                    <div class="card-body">
                                        <div className='asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 glow-card-sec-01">Growing Community</h5>
                                        <p class="card-text">India has 19M+ cryptocurrency investors, with almost 9% being women and around 75% aged 18-35..</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="card h-100 mt-lg-5 alt-new-card-sec-01">
                                    <div class="card-body ">
                                        <div className='asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 glow-card-sec-01">Million Dollar Market</h5>
                                        <p class="card-text">Statista Report says the revenue in the Indian cryptocurrency market is projected to reach US$343.5m in 2024.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="card h-100 alt-new-card-sec-01">
                                    <div class="card-body">
                                        <div className='asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 glow-card-sec-01">Credibility & Liquidity</h5>
                                        <p class="card-text">INR market listing improves credibility and liquidity, meeting regulatory standards and tapping into native currency traders..</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12">
                                <div class="card h-100 mt-lg-5 alt-new-card-sec-01">
                                    <div class="card-body">
                                        <div className='asdsad2w32 d-flex justify-content-center align-items-center' data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FaArrowRight className="tot-arr-btn" /></div>
                                        <h5 class="card-title tot-head-ctn-01 glow-card-sec-01">International Exposure</h5>
                                        <p class="card-text">Listing your token on the INR market offers global exposure, and widens your token's reach to investors worldwide..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className=" whl-carousel-ctn-002 position-relative">
                    <p className="mb-0"><img src={Rocketimg} className="corusel-roc-img" /></p>
                    <p className="mb-0"><img src={Rocketimg} className="corusel-roc-img-01" /></p>
                    <p className="mb-0"><img src={Turncoin} className="corusel-roc-img-02" /></p>
                    <p className="mb-0"><img src={Bitcoin} className="corusel-roc-img-03" /></p>
                    <div className="container">
                        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                            <h2 className="text-center  pt-5 pb-1 caro-head fw-bold">
                                Our Token Listing Process
                            </h2>
                            <div class="carousel-inner carousel-tot-box-ctn">
                                <div class="carousel-item active">
                                    <div className="container">
                                        <div className="row carousel-row-ctn-001 my-5 ">
                                            <div className="col-lg-11  mx-auto all-sub-ctn  d-flex align-items-center justify-content-around">
                                                <div className="d-flex justify-content-around align-items-center car-sub-ctn-00">
                                                    <span className="car-sub-ctn-01 text-black mx-0">1</span>
                                                    <span className="car-sub-ctn-02 mx-2 sub-ctn">
                                                        Submission
                                                    </span>
                                                    <span className="car-sub-ctn-0 car-sub-ctn-005">The token project applies to our platform, providing essential
                                                        information such as project details, and tokenomics.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div className="container">
                                        <div className="row carousel-row-ctn-001 my-5">
                                            <div className="col-lg-11 mx-auto  all-sub-ctn  d-flex align-items-center justify-content-around">

                                                <div className="d-flex justify-content-around align-items-center car-sub-ctn-00">
                                                    <span className="car-sub-ctn-01 text-black mx-2">2</span>
                                                    <span className="car-sub-ctn-02 mx-2 sub-ctn">
                                                        Review
                                                    </span>
                                                    <span className="car-sub-ctn-0 car-sub-ctn-005">Our team conducts a thorough review of the submitted application, assessing various factors about the project.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div className="container">
                                        <div className="row carousel-row-ctn-001 my-5">
                                            <div className="col-lg-11 mx-auto  all-sub-ctn  d-flex align-items-center car-sub-ctn-00 justify-content-around">

                                                <div className="d-flex justify-content-around align-items-center car-sub-ctn-00">
                                                    <span className="car-sub-ctn-01 text-black mx-2">3</span>
                                                    <span className="car-sub-ctn-02 mx-2 sub-ctn">
                                                        Decision
                                                    </span>
                                                    <span className="car-sub-ctn-0 car-sub-ctn-005">Based on the review, a decision is made on whether to list the token on our platform or not.  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div className="container">
                                        <div className="row carousel-row-ctn-001 my-5  ">
                                            <div className="col-lg-11 mx-auto all-sub-ctn   d-flex align-items-center justify-content-around">

                                                <div className="d-flex justify-content-between align-items-center car-sub-ctn-00">
                                                    <span className="car-sub-ctn-01 text-black mx-2">4</span>
                                                    <span className="car-sub-ctn-02 mx-2 sub-ctn">
                                                        Listing
                                                    </span>
                                                    <span className="car-sub-ctn-0 car-sub-ctn-005">If the token is approved, it is listed on our platform, making it available for trading and investment by our users. </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"><FaArrowCircleRight className="right-arrow-01" /></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"><FaArrowCircleLeft className="left-arrow-01" /></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>


                    </div>
                </section>
                {/* <==========> */}
                <section className="whole-mod-sec-top-cross">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">

                                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-header modal-head-tit-01">

                                                {/* <h4 class="modal-title" id="exampleModalLabel">List Your Crypto on GETX</h4> */}
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body coin-form-ctn-01 p-0">
                                                <div className="container px-lg-3 px-1 pb-3">
                                                    <div className="row">
                                                        <div className="col-lg-12">

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 ">
                                                            <div className="row">
                                                                <h2 className="text-center text-white"><span><img src={Getxlogo} className="getxlogo-img" /></span><br />GetX Listing</h2>
                                                                <p className="text-center form-child-ctn-01">Take a few basic steps to submit your listing application.  </p>
                                                                <p className="text-center form-child-ctn-02">Input your details, and our listing representative will connect with you!</p>
                                                                <form>
                                                                    <div>
                                                                        <div className="row">
                                                                            <label for="formGroupExampleInput" class="form-label text-white mb-0">Contact</label>

                                                                            {
                                                                                contact.map((item, index) => {
                                                                                    // console.log("item--", item);
                                                                                    return (
                                                                                        <>
                                                                                            <div className="d-flex flex-row align-items-end">
                                                                                                <label class=" text-white ms-auto curPointer" style={{ fontSize: "20px" }} onClick={() => removeInput(index)} ><RxCrossCircled /></label>
                                                                                            </div>
                                                                                            <div class="mb-2 col-lg-5 col-12">

                                                                                                <Select
                                                                                                    className=""
                                                                                                    aria-label="Default select example"
                                                                                                    onChange={(event) => handleChange(event, index)}
                                                                                                    value={options.find(option => option.value === item.network)}
                                                                                                    name="network"
                                                                                                    defaultCountry="IN"
                                                                                                    options={availableOptions}
                                                                                                    styles={customStyles}
                                                                                                    isSearchable={false}
                                                                                                />
                                                                                                <div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="mb-2 col-lg-7 col-12">

                                                                                                {item.network === "1" ? (
                                                                                                    <PhoneInput
                                                                                                        international
                                                                                                        className="px-0 text-white"
                                                                                                        name="contactNumber"
                                                                                                        defaultCountry={vpnCountry}
                                                                                                        placeholder="Enter phone number"
                                                                                                        onChange={(event) => handleChange(event, index)}
                                                                                                        value={item.contactNumber}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        onChange={(event) => handleChange(event, index)}
                                                                                                        value={item.contactNumber}
                                                                                                        name="contactNumber"
                                                                                                        className="form-control py-2"
                                                                                                        id="formGroupExampleInput"
                                                                                                        placeholder={
                                                                                                            item.network === "2" ? "Enter Telegram Username or URL" :
                                                                                                            item.network === "3" ? "Enter Facebook URL" :
                                                                                                            item.network === "4" ? "Enter Twitter Username or URL" :
                                                                                                            "Enter Contact"
                                                                                                        }
                                                                                                    />
                                                                                                )}

                                                                                                {numberErr[index] && <small className="invalid-phoneno error password-text-33">{numberErr[index]} <br /></small>}
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                hideButton == false ?
                                                                                    <div className="d-flex justify-content-end">
                                                                                        <button type="button" onClick={() => addInput()} className="text-end d-flex align-items-center justify-content-end form-add-contacts-01 mb-auto text-white"><LuPlusCircle className="me-2 text-white" />ADD CONTACTS</button>
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            {/* <div class="mb-3 col-lg-12 col-12">
                                                                                <label for="formGroupExampleInput" class="form-label text-white">Name of the Coin/Token</label>
                                                                                <input type="text" class="form-control py-2" id="formGroupExampleInput" placeholder="Coin/Token Name" />
                                                                            </div> */}
                                                                            <div class="mb-3 col-lg-12 col-12">
                                                                                <label for="formGroupExampleInput" class="form-label text-white">Name of the Coin/Token</label>
                                                                                <input type="text" class="form-control py-2" onChange={e => { setCoinName(e.target.value); setCoinNameErr(""); }} value={coinName} id="formGroupExampleInput" placeholder="Coin/Token Name" />
                                                                                {/* {coinNameErr ? <small className="invalid-phoneno error password-text-33">{coinNameErr} <br /></small> : null} */}
                                                                                {coinNameErr && <small className="invalid-phoneno error password-text-33">{coinNameErr} <br /></small>}
                                                                            </div>
                                                                            {/* <button className="py-3 my-2 tot-form-btn-01 text-white">Send contact to manager</button> */}
                                                                            <button type="button" className="py-3 mb-2 tot-form-btn-02" onClick={create}>Submit and Move to the Next Page</button>
                                                                            <div className="d-flex">
                                                                                <span><AiFillExclamationCircle className="cls-crc-imp-aifill me-2 text-white" /></span>
                                                                                <span className="text-white">Or fill out the listing application form to speed up the listing process.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {/* <============> */}
            </div>
            <Footer />
        </div>
    );
}


