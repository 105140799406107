import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import { GoChevronLeft } from 'react-icons/go';
import { MdQrCode2 } from 'react-icons/md';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { RiArrowDownSFill, RiFileCopyFill } from 'react-icons/ri';
import { BsLink45Deg, BsFillArrowRightCircleFill, BsInfoCircleFill } from 'react-icons/bs';
import { FaExternalLinkAlt } from "react-icons/fa";
import { Form, FormGroup, Label, Input } from "reactstrap"
import { IoClose } from "react-icons/io5";
import $ from "jquery";
import Config from "../core/config";
import { makeRequest } from "../core/services/v1/request";
import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import { toast } from "../core/lib/toastAlert";
import Depositimage from "../assets/images/deposit/depo.png";
import noreplay from "../assets/images/deposit/no-re.png";
import ReactTooltip from "react-tooltip";
import { dateFormat } from '../core/helper/date-format';
import { shortAdrress } from '../core/helper/short-address';
import { useContextData } from '../core/context/index';
import { Bars } from "react-loader-spinner";
import DataTable from 'react-data-table-component';
import QrCode from 'react-qr-code';
import "../assets/style.css";
import notFound from "../assets/images/file.png";

export default function Spot(props) {
  const navigate = useNavigate();

  let query = useParams();
  let currencyId = (query && query.currencyId) ? query.currencyId : "";
  const { siteSettings, myProfile } = useContextData();
  const [currencies, setCurrencies] = useState("");
  const [qrCode, setqrCode] = useState("");
  const [depositData, setdepositData] = useState({ address: "", tag: "" });
  const [depositStatus, setdepositStatus] = useState(null);
  const [networkName, setnetworkName] = useState("");
  const [networkList, setnetworkList] = useState([]);
  const [depositcryptoList, setdepositcryptoList] = useState([]);
  const [defaultNetwork, setdefaultNetwork] = useState("");
  const [defaultCurrency, setdefaultCurrency] = useState("");
  const [defaultCurrencyList, setdefaultCurrencyList] = useState({ currencySymbol: "", image: "" });
  const [currencyList, setCurrencyList] = useState([]);
  const [depositText, setDepositText] = useState("CLAIM DEPOSIT");
  const [particularCurrencyList, setparticularCurrencyList] = useState({});
  const [curCurrencySymbol, setCurCurrencySymbol] = useState("");
  const [loaderStatus, setloaderStatus] = useState(false);
  const [searchval, setSearchval] = useState("");
  const [fillteredData, setFillteredData] = useState([])
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalorders, settotalorders] = useState(0);
  useEffect(() => {
    $(".crypto-workflow-section-close-button").click(function () {
      $(".deposit-second-section").hide();
    });
  }, []);

  useEffect(() => {
    if (myProfile && myProfile.email == "") {
      toast({ type: "error", message: "Please enable email verification" });
      navigate('/my/email-authenticator');
    }
  }, [myProfile]);

  useEffect(() => {
    getparticularHistory(curCurrencySymbol, networkName);
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    let networkBase = localStorage.getItem("networkbase");
    if (networkBase == null) {
      depositETHTRX();
    } else {
      depositETHTRX(networkBase);
    }
  }, [currencyId, myProfile, networkName])

  useEffect(() => {
    getWalletCurrency_func();
    getparticularCurrency_func()
  }, []);

  async function getparticularCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        body: { currencySymbol: "INR" }
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        setparticularCurrencyList(response.data);
      }
    } catch (err) { }
  }

  async function getWalletCurrency_func() {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        let currencyArray = [];
        let currencySymbol = "";
        let image = "";
        let networkName = "";
        let defaultNetwork = "";
        let networkId = "";
        response.data.map((item) => {
          let findSymbol = ""
          if (currencyId != "") {
            let findOne = response.data.find(
              (element) => element.currencySymbol == currencyId
            );
            if (findOne.curnType == "Fiat") {
              findSymbol = response.data[0].currencySymbol;
            } else {
              findSymbol = currencyId;
            }
          } else {
            if (response.data[0].currencySymbol == "PI") {
              navigate('/deposit/Pi')
            } else {
              navigate(`/deposit/${response.data[0].curnType.toLowerCase()}/${response.data[0].currencySymbol}`)
            }
            window.location.reload();
          }
          if (item.currencySymbol == findSymbol) {
            networkId = item.currencyId;
            currencySymbol = item.currencySymbol;
            image = item.image;
            item.ids.length > 0 && item.ids.map((item) => {
              networkName = item.basecoin;
              defaultNetwork = item._id;
              if (item.depositEnable == 0) {
                setdepositStatus(false);
              }
              if (item.depositEnable == 1) {
                setdepositStatus(true);
              }
            });
            setnetworkList(item.ids);
            setdefaultNetwork(defaultNetwork);
          }
          if (item.depositEnable == 1) {
            currencyArray.push({
              value: item._id,
              currencySymbol: item.currencySymbol,
              image: item.image,
              currencyName: item.currencyName,
              currencyType: item.curnType
            });
          }
        });
        setCurrencyList(currencyArray);
        setdefaultCurrencyList({ currencySymbol: currencySymbol, image: image })
        let local = localStorage.getItem("networkbase");
        setnetworkName(local == undefined ? networkName : local);
        setCurCurrencySymbol(currencySymbol);
        setloaderStatus(true);
        getparticularHistory(currencySymbol, networkName);
        createAddress(defaultNetwork);
        getparticularCurrencyDet(defaultNetwork);
      }
    } catch (err) { }
  }

  async function getparticularCurrencyDet(defaultNetwork) {
    const networksId = localStorage.getItem("networkId");
    try {
      const value = { CurrencyID: networksId == undefined ? defaultNetwork : networksId };
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        data: value,
      };
      const result = await makeRequest(params);
      if (result.status) {
        setloaderStatus(false);
        if (result.data.depositEnable == 0) {
          setdepositStatus(false);
        }
        else {
          setdepositStatus(true);
        }
        setCurrencies(result.data);
        let local = localStorage.getItem("networkbase");
        setnetworkName(local == undefined ? result.data.basecoin : local);
      }
    } catch (err) { }
  }
  async function handlenetworkChange(networkId, basecoin, symbol) {
    getselectedNetwork(networkId);
    createAddress(networkId);
    getparticularHistory(symbol, basecoin)
    setnetworkName(basecoin)
    depositETHTRX(basecoin);
    localStorage.setItem("networkId", networkId);
    localStorage.setItem("networkbase", basecoin);
  }
  async function getselectedNetwork(networkId) {
    try {
      setloaderStatus(true);
      const value = { CurrencyID: networkId };
      const params = {
        url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
        method: "POST",
        data: value,
      };
      const response = await makeRequest(params);
      if (response.status) {
        setloaderStatus(false);
        if (response.data.depositEnable == 0) {
          setdepositStatus(false);
        }
        else {
          setdepositStatus(true);
        }
        setCurrencies(response.data);
        let local = localStorage.getItem("networkbase");
        localStorage.setItem("currencyImage", response.data.image);
        setnetworkName(local == undefined ? response.data.basecoin : local);
      } else {
        navigate("/my/dashboard");
      }
    } catch (err) { }
  }
  async function createAddress(networkId) {
    try {
      const value = { CurrencyID: networkId };
      const params = {
        url: `${Config.V1_API_URL}wallet/createAddress`,
        method: "POST",
        data: value,
      };
      const result = await makeRequest(params);
      if (result.status && result.data) {
        setdepositData(result.data);
        setloaderStatus(false);
        const qrCode =
          "https://chart.googleapis.com/chart?cht=qr&chs=150x150&chl=" +
          result.data.address +
          "&choe=UTF-8&chld=L";
        setqrCode(qrCode);
        setWalletAddress(result.data.address)
      }
    } catch (err) {
      console.log("Error!")
    }
  }
  async function handleCurrencyChange(currencySymbol) {
    window.history.pushState({}, '', window.origin + "/deposit/crypto/" + currencySymbol);
    localStorage.removeItem("networkbase")
    localStorage.removeItem("networkId")
    localStorage.removeItem("currencyImage")
    setnetworkName("")
    getSelectedCurrency(currencySymbol);
  }
  async function getSelectedCurrency(findcurrencySymbol) {
    try {
      const params = {
        url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
        method: "GET",
      };
      const response = await makeRequest(params);
      if (response.status && response.data) {
        let currencyArray = [];
        let currencySymbol = "";
        let image = "";
        let network = "";
        let defaultNetwork = "";
        let networkId = "";
        response.data.map((item) => {
          if (item.currencySymbol === findcurrencySymbol) {
            networkId = item.currencyId;
            currencySymbol = item.currencySymbol;
            image = item.image;
            item.ids.map((item) => {
              network = item.basecoin;
              defaultNetwork = item._id;
              if (item.depositStatus == 0) {
                setdepositStatus(false);
              }
              if (item.depositStatus == 1) {
                setdepositStatus(true);
              }
            });
            setnetworkList(item.ids);
            setnetworkName(network)
            setdefaultNetwork(defaultNetwork);
          }
          currencyArray.push({
            value: item._id,
            currencySymbol: item.currencySymbol,
            image: item.image, currencyName:
              item.currencyName,
            currencyType: item.curnType
          });
        });
        setCurrencyList(currencyArray);
        setdefaultCurrencyList({ currencySymbol: currencySymbol, image: image });
        let local = localStorage.getItem("networkbase");
        setnetworkName(local == undefined ? network : local);
        setloaderStatus(true);
        createAddress(defaultNetwork);
        getparticularHistory(currencySymbol, network);
        getparticularCurrencyDet(defaultNetwork);

        setCurCurrencySymbol(currencySymbol);
      }
    } catch (err) { }
  }
  async function getparticularHistory(Currency = "", network) {
    setLoader(true);
    try {
      const networksBase = localStorage.getItem("networkbase");
      if (Currency != "") {
        const value = {
          type: "Deposit",
          currencyType: "Crypto",
          currencySymbol: Currency,
          network: networksBase == undefined ? network : networksBase,
          limit: rowsPerPage,
          offset: rowsPerPage * (currentPage - 1)
        };
        const params = {
          url: `${Config.V1_API_URL}wallet/getHistory`,
          method: "POST",
          data: value,
        };
        const result = await makeRequest(params);
        if (result.status) {
          const array = [];
          result.data.map((item) => {
            if (Currency == item.currencyId.currencySymbol) {
              array.push({
                currencySymbol: item.currencyId.currencySymbol,
                image: item.currencyId.image,
                amount: item.amount,
                fees: item.fees,
                txnId: item.txnId,
                status: item.status,
                createdDate: item.createdDate,
                baseCurrency: item.currencyId.basecoin == "Coin" ? item.currencyId.currencySymbol == "TRX" ? "TRC20" : `${item.currencyId.currencySymbol}20` : item.currencyId.basecoin,
                moveCurrency: item.moveCur
              });
            }
          });
          setdepositcryptoList(array);
          settotalorders(result.total);
          setLoader(false);
        }
      }
    } catch (err) { }
  }
  async function depositETHTRX(basecoin) {
    try {
      if (networkName !== "") {
        const value = { "Currency": curCurrencySymbol, "network": basecoin ? basecoin : networkName };
        const params = {
          url: `${Config.V1_API_URL}wallet/depositETHTRX`,
          method: "POST",
          data: value
        };
        const response = await makeRequest(params);
        getparticularHistory(curCurrencySymbol, basecoin ? basecoin : networkName);
      } else {
        console.log("networkName not called", networkName);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  const missingDeposit = async () => {
    setDepositText('Loading...')
    if (networkName !== "") {
      const value = { "Currency": currencyId, "network": networkName };
      const params = {
        url: `${Config.V1_API_URL}wallet/depositETHTRX`,
        method: 'POST',
        data: value
      }
      const response = (await makeRequest(params));
      if (response.status) {
        let type = 'success';
        toast({ type, message: "Recent deposit updated sucessfully" });
        setDepositText('CLAIM DEPOSIT');
      } else {
        // let type = 'error';
        // toast({ type, message: response.msg });
        setDepositText('CLAIM DEPOSIT')
      }
    }
    getparticularHistory(curCurrencySymbol, networkName);
  }

  function copyToColumn(data = {}) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    toast({ type: "success", message: data.message });
  }

  useEffect(() => {
    const filterCurrencyList = async () => {
      const searchString = searchval ? searchval.toString().toLowerCase() : "";
      if (!searchString) {
        return currencyList.filter(currency => currency.currencyType !== "Fiat");
      } else {
        return currencyList.filter(currency =>
          (currency.currencySymbol.toLowerCase().includes(searchString) && currency.currencyType !== "Fiat")
          ||
          (currency.currencyName.toLowerCase().includes(searchString) && currency.currencyType !== "Fiat")
        );
      }
    };

    if (currencyList.length > 0) {
      filterCurrencyList().then(filteredData => {
        setFillteredData(filteredData);
      });
    } else {
      getWalletCurrency_func();
    }
  }, [searchval, currencyList]);
  function bonusAmount(trxn) {
    try {
      if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
        return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
      } else {
        if (trxn.depositType == 'Pre Booking') {
          return '-';
        } else {
          return 'NA';
        }
      }
    } catch (err) { }
  }
  let columns = [];
  let filteredItems = []
  filteredItems = depositcryptoList.filter(
    item => item.txnId && item.txnId.toLowerCase().includes(filterText.toLowerCase()),
  )
  columns = [
    {
      name: "Sl.No",
      selector: (row, index) => rowsPerPage * (currentPage - 1) + index + 1,
      width: "70px",
    },
    {
      name: 'Assets',
      selector: row => (<>{row.image ? <img src={row?.image} alt={Config.SITENAME} width="20px" height="20px" className="img-fluid img-res" style={{ height: '20%', width: '20%' }} /> : ""} {""} {row?.currencySymbol}</>),
      sortable: false
    },
    {
      name: 'Amount',
      selector: row => row?.amount,
      sortable: false
    },
    {
      name: "Network",
      selector: row => row?.baseCurrency,
      sortable: false
    },
    {
      name: 'Bonus',
      selector: row => bonusAmount(row),
      sortable: false
    },
    {
      name: 'Txn Id',
      width: '290px',
      selector: row => <>
        {shortAdrress(row?.txnId != "" ? row?.txnId : "-")}<FaExternalLinkAlt style={{ cursor: "pointer" }} onClick={() => {
          row.baseCurrency == "ERC20" || row.moveCurrency == "ETH" ?
            window.open(Config.ETH_EXPLORER_URL + row.txnId, "_blank") :
            row.baseCurrency == "TRC20" || row.moveCurrency == "TRX" ?
              window.open(Config.TRX_EXPLORER_URL + row.txnId, "_blank") :
              row.baseCurrency == "BEP20" || row.moveCurrency == "BNB" ?
                window.open(Config.BNB_EXPLORER_URL + row.txnId, "_blank") :
                row.baseCurrency == "DCX20" || row.moveCurrency == "DCX" ?
                  window.open(Config.DCX_EXPLORER_URL + row.txnId, "_blank") :
                  row.baseCurrency == "CRO20" || row.moveCurrency == "CRO" ?
                    window.open(Config.CRO_EXPLORER_URL + row.txnId, "_blank") :
                    row.baseCurrency == "ARBI20" || row.moveCurrency == "ARBI" ?
                      window.open(Config.ARBI_EXPLORER_URL + row.txnId, "_blank") :
                      row.baseCurrency == "AVAX20" || row.moveCurrency == "AVAX" ?
                        window.open(Config.AVAX_EXPLORER_URL + row.txnId, "_blank") :
                        row.baseCurrency == "FTM20" || row.moveCurrency == "FTM" ?
                          window.open(Config.FTM_EXPLORER_URL + row.txnId, "_blank") :
                          row.baseCurrency == "OPTM20" || row.moveCurrency == "OPTM" ?
                            window.open(Config.OPTM_EXPLORER_URL + row.txnId, "_blank") :
                            row.baseCurrency == "MATIC20" || row.moveCurrency == "MATIC" ?
                              window.open(Config.MATIC_EXPLORER_URL + row.txnId, "_blank") : window.location.reload();
        }} /></>,
      sortable: false
    },
    {
      name: 'Status',
      selector: row => row.status == 0 ? 'Pending' : row.status == 1 ? "Approved" : row.status == 2 ? 'Rejected' : 'Processing',
      sortable: false
    },
    {
      name: 'Date',
      width: '190px',
      selector: row => dateFormat(row?.createdDate)
    }
  ]
  const customSort = (rows, selector, direction) => {
    return rows.sort((a, b) => {
      if (typeof selector(a) == "string") {
        const aField = selector(a).toLowerCase();
        const bField = selector(b).toLowerCase();

        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      } else {
        const aField = selector(a).props.children ? parseFloat(selector(a).props.children) : 0
        const bField = selector(b).props.children ? parseFloat(selector(b).props.children) : 0
        let comparison = 0;

        if (aField > bField) {
          comparison = 1;
        } else if (aField < bField) {
          comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
      }
    });
  };
  useEffect(() => {
    const modalElement = document.getElementById("selectcoinModal");
    const handleModalClose = () => {
      setSearchval("");
    };
    modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    return () => {
      modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);
  const goBack = () => {
    localStorage.removeItem("networkbase");
    navigate("/my/dashboard")
  }
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
      />
      <div className="deposit-page-top-banner">
        <div className="deposit-hero-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6" onClick={goBack}>
                <GoChevronLeft className="deposit-back-button-icon" />
                <span className="deposit-text-1">Deposit Crypto</span>
              </div>
              <div className="col-6 text-end">
                {Config.depositFiatStatus == "Enable" ?
                  <button type="button" className="deposit-fiat-button" onClick={() => navigate("/deposit/fiat/" + particularCurrencyList?.currencySymbol)}>
                    Deposit Fiat <HiArrowNarrowRight />
                  </button> : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="container deposit-second-section">
          <div className="row align-items-center">
            <div className="col">
              <div className="d-flex flex-row mb-2">
                <div className="ms-auto me-lg-5">
                  <IoClose className="crypto-workflow-section-close-button" />
                </div>
              </div>
              <div className="d-flex flex-row bd-highlight">
                <div className="d-lg-block d-none">
                  <img
                    className="deposit-crupto-explain-image"
                    src={Depositimage}
                    alt="Depositimage"
                  />
                </div>
                <div className="">
                  <ul className="base-timeline p-0 mb-0">
                    <li className="base-timeline__item">
                      <span className="base-timeline__summary-text">
                        Copy address
                      </span>
                      <br />
                      <span className="deposit-text-2">
                        Copy your exchange's deposit address for the specific cryptocurrency.
                      </span>
                    </li>
                    <li className="base-timeline__item">
                      <span className="base-timeline__summary-text">
                        Initiate a Withdrawal
                      </span>
                      <br />
                      <span className="deposit-text-2">
                        Go to your wallet, paste the address, and start the transfer.
                      </span>
                    </li>
                    <li className="base-timeline__item">
                      <span className="base-timeline__summary-text">
                        Network Confirmation
                      </span>
                      <br />
                      <span className="deposit-text-2">
                        Wait for blockchain to confirm and validate your transaction.
                      </span>
                    </li>
                    <li className="base-timeline__item">
                      <span className="base-timeline__summary-text">
                        Deposit Successful
                      </span>
                      <br />
                      <span className="deposit-text-2">
                        Check your exchange account to ensure funds are credited.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container deposit-third-section">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="d-flex flex-lg-row flex-column">
                <div className="deposit-third-right-section">
                  <span className="deposit-text-33 password-text-44  color-blue">Select Coin / Token</span>
                  <div
                    className="d-flex flex-row align-items-center deposit-select-coin-section"
                    data-bs-toggle="modal"
                    data-bs-target="#selectcoinModal"
                  >
                    <div className="">
                      {defaultCurrencyList && defaultCurrencyList.image ?
                        <img
                          src={localStorage.getItem("currencyImage") ? localStorage.getItem("currencyImage") : defaultCurrencyList && defaultCurrencyList.image}
                          className="deposit-logo-image"
                          alt="logo"
                        /> : ""}
                      <span key={'i'} className="deposit-text-4"> {defaultCurrencyList && defaultCurrencyList.currencySymbol} </span>
                    </div>
                    <div className="ms-auto">
                      <RiArrowDownSFill />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-lg-row flex-column mt-5">
                <div className="deposit-third-right-section">
                  <span className="deposit-text-33 deposit-text-33 password-text-44  color-blue">Select Network</span>
                  <div
                    className="d-flex flex-row align-items-center deposit-select-coin-section"
                    data-bs-toggle="modal"
                    data-bs-target="#depositnetworkModal"
                  >
                    <div className="">
                      {/* <img src="https://seeklogo.com/images/E/ethereum-logo-EC6CDBA45B-seeklogo.com.png" className='deposit-logo-image' alt="logo" /> */}
                      <span className="deposit-text-4"></span>
                      <span className="deposit-text-5">{networkName == "Coin"
                        ?
                        defaultCurrencyList.currencySymbol
                        :
                        networkName
                      }</span>
                    </div>
                    <div className="ms-auto">
                      <RiArrowDownSFill />
                    </div>
                  </div>
                </div>
              </div>

              {loaderStatus == true ?
                <div className="d-flex flex-row">
                  <div className="crypto-deposit-loading-loader mx-auto">
                    <Bars
                      height="60"
                      width="60"
                      color="#4fa94d"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperclassName=""
                      visible={true}
                    />
                  </div>
                </div>
                :
                <div className="d-flex flex-row mt-4">
                  <div className="deposit-third-left-section d-none">
                    <span className="deposit-text-3 visually-hidden">
                      Deposit to
                    </span>
                  </div>
                  {/* {defaultCurrency == "INR" ?
                    <div className="deposit-third-left-section">
                      <span className="deposit-text-33">{(currencies?.depositEnable == 0) ? defaultCurrency + "  Deposit Disabled" : ""}</span>
                    </div>
                    :
                    <div className="deposit-third-left-section">
                      <span className="deposit-text-33">{(currencies?.depositEnable == 0) ? networkName + "  Deposit Disabled" : ""}</span>
                    </div>
                  } */}
                  {(currencies?.depositEnable != 0 && depositStatus == true) &&
                    <div className="deposit-third-right-section">
                      {depositData && depositData.tag != '' ?
                        <>
                          <span className="deposit-text-33 color-blue">Tag</span>
                          <div className="d-flex flex-row">
                            <div className="">
                              <span className="deposit-text-6">
                                {depositData && depositData.tag ? depositData.tag : <>&nbsp;</>}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <span data-tip data-for="noOverridePosition">
                                <RiFileCopyFill className="deposit-icon-1" onClick={() => copyToColumn({
                                  message: "Destination Tag copied successfully!",
                                  text: depositData && depositData.tag != '' ? depositData.tag : '-'
                                })} />
                              </span>
                              <ReactTooltip
                                id="noOverridePosition"
                                place="top"
                                className="tooltip-text-Theme text-center"
                              >
                                <span className="tooltip-text-1">Click to Copy</span>
                              </ReactTooltip>
                            </div>
                          </div>
                        </> : ""}

                      {depositData && depositData.address ?
                        <>
                          <span className="deposit-text-33 color-blue">Address</span>
                          <div className="d-flex flex-row">
                            <div className="">
                              <span className="deposit-text-6">
                                {depositData && depositData.address ? depositData.address : <>&nbsp;</>}
                              </span>
                            </div>
                            <div className="ms-auto">
                              <span data-tip data-for="noOverridePosition">
                                <RiFileCopyFill className="deposit-icon-1" onClick={() => copyToColumn({
                                  message: "Address copied successfully!",
                                  text: depositData && depositData.address != '' ? depositData.address : '-'
                                })} />
                              </span>
                              <ReactTooltip
                                id="noOverridePosition"
                                place="top"
                                className="tooltip-text-Theme text-center"
                              >
                                <span className="tooltip-text-1">Click to Copy</span>
                              </ReactTooltip>
                            </div>
                            <div className="">
                              <span data-tip data-for="noOverridePosition2">
                                <MdQrCode2 className="deposit-icon-1" />
                              </span>
                              <ReactTooltip
                                id="noOverridePosition2"
                                effect="solid"
                                place="right"
                                className="tooltip-text-Theme-2"
                                style={{ backgroundcolor: "red" }}
                              >
                                <p className="tooltip-text-1 mb-3">
                                  Scan the code on the withdrawal page of the platform APP or Wallet APP
                                </p>
                                {walletAddress && (
                                  <div className="text-center">
                                    <QrCode
                                      size={256}
                                      style={{ height: "200px", maxWidth: "100%", width: "200px" }}
                                      value={walletAddress}
                                      viewBox={"0 0 256 256"}
                                    />
                                  </div>
                                )}
                                <ul className="p-0 ps-2 mt-4">
                                  <li className="tooltip-text-1">
                                    Send only {currencies.currencySymbol} {currencies.basecoin != 'Coin' ? '- ' + currencies.basecoin : ''} to this deposit address.
                                  </li>
                                  <li className="tooltip-text-1">
                                    Ensure the network is {networkName == "Coin"
                                      ?
                                      defaultCurrencyList.currencySymbol
                                      :
                                      networkName}
                                  </li>
                                </ul>
                              </ReactTooltip>
                            </div>
                          </div>
                        </>
                        : <>&nbsp;</>
                      }

                      <div className="row mt-3"></div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <ul>
                            {
                              currencies && currencies.minCurDeposit > 0 ?
                                <li>
                                  <span className="deposit-text-3">
                                    Minimum deposit for this coin or token is <span className="deposit-text-9">{currencies.minCurDeposit} {currencies.currencySymbol}</span>. Deposits below this amount cannot be recovered.
                                  </span>
                                </li> : ""}

                            {(currencies && currencies.currencySymbol && currencies.basecoin) ?
                              <li>
                                <span className="deposit-text-3">
                                  Send only{" "}
                                  <span className="deposit-text-9">{currencies.currencySymbol} {currencies.basecoin != 'Coin' ? '- ' + currencies.basecoin : ''}</span> to this
                                  deposit address.
                                </span>
                              </li> : ""}
                            {currencies && currencies.depositNotes != "NA" && <li>
                              <span className="deposit-text-3">
                                {currencies.depositNotes}
                              </span>
                            </li>}
                            <li>
                              <span className="deposit-text-3">
                                After deposit kindly refresh after 5 - 10 minutes to see your deposits.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>

        <div className="container deposit-fourth-section mb-5">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <h1 className="deposit-text-11">Recent Deposits</h1>

              <p className='mt-12'>
                If your previous deposit is not reflecting, you can claim your deposit below &nbsp;
              </p>
              <button className='fc-b f-15 m-top-20 cursor color-hover-res hover-hand-csss new-calim-csxas' onClick={() => missingDeposit()}>
                {depositText} {" "}
                {
                  loader ?
                    <div class="spinner-border text-primary spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    :
                    ''
                }
              </button>
              <div className="col-lg-5 mt-3">
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">Search</Label>
                    <Input
                      id="search"
                      type="text"
                      placeholder="Filter By Transaction id"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </FormGroup>
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={filteredItems}
                persistTableHead
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[5, 10, 15]}
                paginationTotalRows={totalorders}
                onChangeRowsPerPage={newRowsPerPage => {
                  setRowsPerPage(newRowsPerPage);
                  setCurrentPage(1); // Reset to first page when rows per page changes
                }}
                onChangePage={page => setCurrentPage(page)}
                noDataComponent={
                  <div className="text-center">
                    <img className="no-record-image" src={notFound} alt="no-record" style={{ width: 70, height: 70 }} />
                    <br />
                    <span className="text-center">No Records Found</span>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade deposit-crypto-modal"
        id="selectcoinModal"
        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header ">
              <h4 className="modal-title" id="exampleModalLabel">
                Select coin / token to deposit
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              <input
                id="myInput2"
                type="text"
                placeholder="Search here..."
                value={searchval}
                autoComplete="off"
                name="search"
                onChange={(e) => setSearchval(e.target.value)}
                style={{ position: 'sticky' }}
              />
              <div className="modal-2-overflow-scroll">
                <ul id="myUL2" >
                  {fillteredData.length > 0 ? (<>
                    {fillteredData.map((currency, i) => {
                      if (currency.currencySymbol != 'INR') {
                        return (
                          <li onClick={() => {
                            if (currency.currencySymbol != "PI") {
                              handleCurrencyChange(currency.currencySymbol)
                            } else {
                              navigate("/deposit/Pi")
                            }
                          }} key={i + 1}>
                            <a
                              data-bs-dismiss="modal"
                              className="deposit-modal-list-sizing"
                            >
                              <div className="row deposit-modal-row-sizing">
                                <div className="col-1">
                                  <img
                                    src={currency.image}
                                    alt="logo"
                                    className="me-1 deposit-modal-coin-images"
                                  />
                                </div>
                                <div className="col">
                                  <div className="row ps-2">
                                    <span className="deposit-modal-coin-heading">
                                      {currency.currencySymbol}
                                    </span>
                                    <span className="deposit-modal-coin-muted-text ">
                                      {currency.currencyName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                        )
                      }
                    })}
                  </>
                  ) : (
                    <>
                      <p className="text-center">No Coin Found</p>
                    </>
                  )
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div
        className="modal fade deposit-crypto-modal"
        id="depositnetworkModal"
        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Select network
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body modal-2-overflow-scroll">
              <p className="select-network-text-1">
                Please note that only supported networks on Binance platform are shown, if you deposit via another network your assets may be lost.
              </p>
              <ul id="myUL2">
                {networkList.length > 0 && networkList.map((item, i) => {
                  if (item._id == defaultNetwork) {
                    return (
                      // networkName == item.basecoin ? networkName : item._id
                      <li selected={true} onClick={() => handlenetworkChange(item._id, item.basecoin, defaultCurrencyList.currencySymbol)}>
                        <a
                          data-bs-dismiss="modal"
                          className="deposit-modal-list-sizing"
                        >
                          <div className="row deposit-modal-network-sizing">
                            <span className="deposit-modal-coin-heading">
                              {/* {networkName == item.basecoin ? networkName : item.basecoin} */}
                              {networkName == item.basecoin
                                ?
                                networkName == "Coin"
                                  ?
                                  defaultCurrencyList.currencySymbol
                                  :
                                  networkName
                                :
                                item.basecoin}
                            </span>
                            <span className="deposit-modal-coin-muted-text ">
                              {/* BNB Smart Chain (BEP20) */}
                            </span>
                          </div>
                        </a>
                      </li>
                    )
                  } else {
                    return (
                      <li onClick={() => handlenetworkChange(item._id, item.basecoin, defaultCurrencyList.currencySymbol)}>
                        <a
                          data-bs-dismiss="modal"
                          className="deposit-modal-list-sizing"
                        >
                          <div className="row deposit-modal-network-sizing">
                            <span className="deposit-modal-coin-heading">{networkName == item.basecoin ? networkName : item.basecoin}</span>
                            <span className="deposit-modal-coin-muted-text ">
                              {/* BNB Smart Chain (BEP20) */}
                            </span>
                          </div>
                        </a>
                      </li>
                    )
                  }
                })
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}
