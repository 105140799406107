import { useEffect, useState } from "react";
import { showNumber } from "../../core/helper/date-format";
import { BiChevronDown } from "react-icons/bi";
import { MdCandlestickChart } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import moment from "moment";
import Loader from "./Loader";
import { Bars } from "react-loader-spinner";
function AllOptionTable({ allOption }) {

  function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
      return showNumber(parseFloat(value));
    }
    return showNumber(parseFloat(value).toFixed(decimal));
  }

  const findOneOptionData = (smbl) => {
    window.location.href = `eoption/${smbl}`;
  };

  let orgDate;
  if (allOption && allOption.callData) {
    allOption.callData.map((call) => {
      const date = call.optionalTradeSymbol.split("-")[1];
      orgDate = moment(date, 'YYMMDD').format("YYYY-MM-DD")
    });
  }

  return (
    <div>
      <div className="eoption-left-tabs-3">
        <div className="d-flex flex-lg-row justify-content-lg-around justify-content-between align-items-center eoption-left-tabs-3-1 ">
          <div className="text-center eiption-table-row-data-hidden order-lg-1">
            <span className="eoption-left-text-3">Calls</span>
          </div>
          <div className="order-lg-2 order-2">
            <span className="eoption-left-text-5 eiption-table-row-data-hidden">
              Index Price: 30,350
            </span>
            <span className="eoption-left-text-4 mx-2">{orgDate}</span>
            <span className="eoption-left-text-5 eiption-table-row-data-hidden">
              Index Price: 30,350
            </span>
          </div>
          <div className="text-center eiption-table-row-data-hidden order-lg-3">
            <span className="eoption-left-text-3">Puts</span>
          </div>

          <ul
            class="mobile-hidden-tabs-option nav nav-pills d-lg-none order-lg-4 order-1 gap-2"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-calls-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-calls"
                type="button"
                role="tab"
                aria-controls="pills-calls"
                aria-selected="true"
              >
                Calls
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-puts-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-puts"
                type="button"
                role="tab"
                aria-controls="pills-puts"
                aria-selected="false"
              >
                Puts
              </button>
            </li>
          </ul>
        </div>

        <div class="tab-content d-flex flex-row" id="pills-tabContent">
          <div
            class="tab-pane fade show active eoption-left-table-calls order-lg-1 order-2"
            id="pills-calls"
            role="tabpanel"
            aria-labelledby="pills-calls-tab"
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th>Show</th>
                  <th scope="col">Delta</th>
                  <th scope="col">Bid / IV</th>
                  <th scope="col">Mark / IV</th>
                  <th scope="col">Ask / IV</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {allOption &&
                  allOption.callData &&
                  allOption.callData.length > 0 ? (
                  <>
                    {allOption.callData.map((tomorrowCallData, index) => {
                      const buyImpliedVolatility =
                        tomorrowCallData.buyImpliedVolatility * 100;
                      const selImpliedVlotility =
                        tomorrowCallData.sellImpliedVolatility * 100;
                      const impliedVolatility =
                        tomorrowCallData.impliedVolatility * 100;
                      const symbol = tomorrowCallData.optionalTradeSymbol;
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              verticalAlign: "middle",
                            }}
                            className="eiption-table-row-active-dropdown-data"
                          >
                            <div class="dropdown">
                              <button
                                class="eiption-table-row-dropdown"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <BiChevronDown />
                              </button>
                              <ul
                                class="dropdown-menu eiption-table-dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Leverage
                                        </p>
                                        <p className="eoption-left-text-6">
                                          6.0765x
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          IV (Bid)
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            buyImpliedVolatility,
                                            tomorrowCallData.decimalValue
                                          )}
                                          %
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          IV (Ask)
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            selImpliedVlotility,
                                            tomorrowCallData.decimalValue
                                          )}
                                          %
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row mt-2">
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          24h High
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {tomorrowCallData.highPrice}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          24h Low
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {tomorrowCallData.lowPrice}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          volume
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {tomorrowCallData.tradeVolContracts}%
                                        </p>
                                      </div>
                                    </div>
                                    <p className="eoption-left-text-7 mt-2">
                                      The Greeks
                                    </p>
                                    <div className="d-flex flex-row mt-1">
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Delta
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowCallData.delta,
                                            tomorrowCallData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Gamma
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowCallData.gamma,
                                            tomorrowCallData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Theta
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowCallData.theta,
                                            tomorrowCallData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row mt-2">
                                      <div className="col-12">
                                        <p className="eoption-left-text-6 text-muted">
                                          Vega
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowCallData.vega,
                                            tomorrowCallData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="eoption-left-text-7 mt-2">
                                      Order Books
                                    </p>
                                    <div class="d-flex flex-row mb-1 mobile-justify-content border-top-0 eoption-left-text-9 mt-3">
                                      <div class="progress-table-1">
                                        Price(USDT)
                                      </div>
                                      <div class="progress-table-2">
                                        Amount(BTC)
                                      </div>
                                      <div class="progress-table-3">
                                        Total(USDT)
                                      </div>
                                    </div>
                                    <div class="d-flex flex-row mobile-justify-content position-relative eoption-left-text-8">
                                      <div
                                        class="progress-bar ask-bar"
                                        style={{
                                          width: "50%",
                                          height: "20px",
                                        }}
                                      ></div>
                                      <div class="progress-table-1 color-red">
                                        <span class="orderbook-text-2">
                                          30,268.2700
                                        </span>
                                      </div>
                                      <div class="progress-table-2">
                                        <span class="orderbook-text-2">
                                          0.03116
                                        </span>
                                      </div>
                                      <div class="progress-table-3">
                                        <span class="orderbook-text-2">
                                          943.12765
                                        </span>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-row mobile-justify-content position-relative eoption-left-text-8">
                                      <div
                                        class="progress-bar ask-bar"
                                        style={{
                                          width: "50%",
                                          height: "20px",
                                        }}
                                      ></div>
                                      <div class="progress-table-1 color-red">
                                        <span class="orderbook-text-2">
                                          30,268.2700
                                        </span>
                                      </div>
                                      <div class="progress-table-2">
                                        <span class="orderbook-text-2">
                                          0.03116
                                        </span>
                                      </div>
                                      <div class="progress-table-3">
                                        <span class="orderbook-text-2">
                                          943.12765
                                        </span>
                                      </div>
                                    </div>
                                    <p className="eoption-left-text-7 mt-2">
                                      5
                                    </p>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </td>
                          <td>
                            <button onClick={() => findOneOptionData(symbol)}>
                              <MdCandlestickChart />
                            </button>
                          </td>
                          <td>
                            {decimalValue(
                              tomorrowCallData.delta,
                              tomorrowCallData.decimalValue
                            )}
                          </td>
                          <td>
                            <div>
                              <p className="mb-0" style={{ color: "green" }}>
                                {tomorrowCallData.buyPrice}
                              </p>
                              <span style={{ opacity: "0.8" }}>
                                {decimalValue(
                                  buyImpliedVolatility,
                                  tomorrowCallData.decimalValue
                                )}
                                %
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0">
                                {tomorrowCallData.markPrice}
                              </p>
                              <span style={{ opacity: "0.8" }}>
                                {decimalValue(
                                  impliedVolatility,
                                  tomorrowCallData.decimalValue
                                )}
                                %
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0" style={{ color: "red" }}>
                                {tomorrowCallData.sellPrice}
                              </p>
                              <span style={{ opacity: "0.8" }}>
                                {decimalValue(
                                  selImpliedVlotility,
                                  tomorrowCallData.decimalValue
                                )}
                                %
                              </span>
                            </div>
                          </td>
                          <td
                            style={{
                              verticalAlign: "middle",
                            }}
                            className="eiption-table-row-active-close-data"
                          >
                            <button className="eiption-table-row-active-close">
                              <AiFillCloseCircle />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (<></>
                  // <div className="d-flex flex-row align-items-center px-4">
                  //   <div className="bars-loading-loader mx-auto">
                  //     <Bars
                  //       height="80"
                  //       width="80"
                  //       color="#5928E9"
                  //       ariaLabel="bars-loading"
                  //       wrapperStyle={{}}
                  //       wrapperclassName=""
                  //       visible={true}
                  //     />
                  //   </div>
                  // </div>
                )}
              </tbody>
            </table>
          </div>

          <div className="eoption-left-table-strike order-lg-2 order-1 text-center">
            <div className="d-flex flex-row align-items-center">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th scope="col" className="fw-bold">
                      Strike
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allOption.callData && allOption.callData.length > 0 ? (
                    <>
                      {allOption.callData.map((datas, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div>
                                <p className="mb-0 fw-bold">
                                  {datas.strikePrice}
                                </p>
                                <span style={{ opacity: "0" }}>-</span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <> <div className="d-flex flex-row align-items-center py-4 my-6 bg-white">
                    <div className="bars-loading-loader mx-auto">
                      <Bars
                        height="60"
                        width="60"
                        color="#5928E9"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperclassName=""
                        visible={true}
                        
                      />
                    </div>
                  </div></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade show active eoption-left-table-puts order-lg-3 order-2"
            id="pills-puts"
            role="tabpanel"
            aria-labelledby="pills-puts-tab"
          >
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Bid / IV</th>
                  <th scope="col">Mark / IV</th>
                  <th scope="col">Ask / IV</th>
                  <th scope="col">Delta</th>
                  <th scope="col">Show</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {allOption &&
                  allOption.putsData &&
                  allOption.putsData.length > 0 ? (
                  <>
                    {allOption.putsData.map((tomorrowPutData, index) => {
                      const buyImpliedVolatility =
                        tomorrowPutData.buyImpliedVolatility * 100;
                      const selImpliedVlotility =
                        tomorrowPutData.sellImpliedVolatility * 100;
                      const impliedVolatility =
                        tomorrowPutData.impliedVolatility * 100;
                      const symbol = tomorrowPutData.optionalTradeSymbol;
                      return (
                        <tr className="eiption-table-row-data" key={index}>
                          <td
                            style={{
                              verticalAlign: "middle",
                            }}
                            className="eiption-table-row-active-close-data"
                          >
                            <button className="eiption-table-row-active-close">
                              <AiFillCloseCircle />
                            </button>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0" style={{ color: "green" }}>
                                {tomorrowPutData.buyPrice}
                              </p>
                              <span style={{ opacity: "0.8" }}>
                                {decimalValue(
                                  buyImpliedVolatility,
                                  tomorrowPutData.decimalValue
                                )}
                                %
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0">
                                {tomorrowPutData.markPrice}
                              </p>
                              <span style={{ opacity: "0.8" }}>
                                {decimalValue(
                                  impliedVolatility,
                                  tomorrowPutData.decimalValue
                                )}
                                %
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <p className="mb-0" style={{ color: "red" }}>
                                {tomorrowPutData.sellPrice}
                              </p>
                              <span style={{ opacity: "0.8" }}>
                                {decimalValue(
                                  selImpliedVlotility,
                                  tomorrowPutData.decimalValue
                                )}
                                %
                              </span>
                            </div>
                          </td>
                          <td>
                            {decimalValue(
                              tomorrowPutData.delta,
                              tomorrowPutData.decimalValue
                            )}
                          </td>
                          <td>
                            <button onClick={() => findOneOptionData(symbol)}>
                              <MdCandlestickChart />
                            </button>
                          </td>
                          <td
                            style={{ verticalAlign: "middle" }}
                            className="eiption-table-row-active-dropdown-data"
                          >
                            <div class="dropdown">
                              <button
                                class="eiption-table-row-dropdown"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <BiChevronDown />
                              </button>
                              <ul
                                class="dropdown-menu eiption-table-dropdown-menu eiption-table-dropdown-menu-2"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="d-flex flex-row">
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Leverage
                                        </p>
                                        <p className="eoption-left-text-6">
                                          6.0765x
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          IV (Bid)
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {" "}
                                          {decimalValue(
                                            buyImpliedVolatility,
                                            tomorrowPutData.decimalValue
                                          )}
                                          %
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          IV (Ask)
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            selImpliedVlotility,
                                            tomorrowPutData.decimalValue
                                          )}
                                          %
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row mt-2">
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          24h High
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {tomorrowPutData.highPrice}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          24h Low
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {tomorrowPutData.lowPrice}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          volume
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {tomorrowPutData.tradeVolContracts}%
                                        </p>
                                      </div>
                                    </div>
                                    <p className="eoption-left-text-7 mt-2">
                                      The Greeks
                                    </p>
                                    <div className="d-flex flex-row mt-1">
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Delta
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowPutData.delta,
                                            tomorrowPutData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Gamma
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowPutData.gamma,
                                            tomorrowPutData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-4">
                                        <p className="eoption-left-text-6 text-muted">
                                          Theta
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowPutData.theta,
                                            tomorrowPutData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-row mt-2">
                                      <div className="col-12">
                                        <p className="eoption-left-text-6 text-muted">
                                          Vega
                                        </p>
                                        <p className="eoption-left-text-6">
                                          {decimalValue(
                                            tomorrowPutData.vega,
                                            tomorrowPutData.decimalValue
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <p className="eoption-left-text-7 mt-2">
                                      Order Books
                                    </p>
                                    <div class="d-flex flex-row mb-1 mobile-justify-content border-top-0 eoption-left-text-9 mt-3">
                                      <div class="progress-table-1">
                                        Price(USDT)
                                      </div>
                                      <div class="progress-table-2">
                                        Amount(BTC)
                                      </div>
                                      <div class="progress-table-3">
                                        Total(USDT)
                                      </div>
                                    </div>
                                    <div class="d-flex flex-row mobile-justify-content position-relative eoption-left-text-8">
                                      <div
                                        class="progress-bar ask-bar"
                                        style={{
                                          width: "50%",
                                          height: "20px",
                                        }}
                                      ></div>
                                      <div class="progress-table-1 color-red">
                                        <span class="orderbook-text-2">
                                          30,268.2700
                                        </span>
                                      </div>
                                      <div class="progress-table-2">
                                        <span class="orderbook-text-2">
                                          0.03116
                                        </span>
                                      </div>
                                      <div class="progress-table-3">
                                        <span class="orderbook-text-2">
                                          943.12765
                                        </span>
                                      </div>
                                    </div>
                                    <div class="d-flex flex-row mobile-justify-content position-relative eoption-left-text-8">
                                      <div
                                        class="progress-bar ask-bar"
                                        style={{
                                          width: "50%",
                                          height: "20px",
                                        }}
                                      ></div>
                                      <div class="progress-table-1 color-red">
                                        <span class="orderbook-text-2">
                                          30,268.2700
                                        </span>
                                      </div>
                                      <div class="progress-table-2">
                                        <span class="orderbook-text-2">
                                          0.03116
                                        </span>
                                      </div>
                                      <div class="progress-table-3">
                                        <span class="orderbook-text-2">
                                          943.12765
                                        </span>
                                      </div>
                                    </div>
                                    <p className="eoption-left-text-7 mt-2">
                                      5
                                    </p>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                 <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllOptionTable;
